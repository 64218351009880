import React, { useEffect } from 'react';
import { MastermindPlusProps } from '../../mastermind-texts';

type Props = {
	meetingNumber: number;
	mainColor: string;
};

export default function CykleSpotkan({ meetingNumber, mainColor }: Props) {
	const CycleData: MastermindPlusProps.MastermindPlusMeetingCycle = MastermindPlusProps.MeetingCycleData.find(
		(x) => x.pageId === meetingNumber
	) as MastermindPlusProps.MastermindPlusMeetingCycle;

	return (
		<>
			<h1 className="text-6xl font-medium">{CycleData.pageTitle}</h1>
			<div className="flex flex-col overflow-y-auto max-h-[90vh]">
				<div className="flex flex-col cycle-content mt-32 ">
					<div className="cycle-row">
						<span>korzyści</span>
						<p>{CycleData.korzysci}</p>
					</div>
					<div className="mastermind-plus-spacer relative">
						<div className="bg-white px-2 py-1 text-black font-semibold rounded-md w-fit absolute right-[-1px] top-1/2 translate-y-[-50%] text-xs">
							{CycleData.firstPart.duration}
						</div>
					</div>
					<div className="flex flex-col gap-5">
						<div className="cycle-row">
							<span className="relative">
								temat przewodni
								<div
									className={`px-4 py-1 text-white font-semibold rounded-md w-fit absolute right-[0] translate-x-[100%] top-[-30px] translate-y-[-50%] text-xs uppercase tracking-widest`}
									style={{
										backgroundColor: mainColor,
									}}>
									Część 1
								</div>
							</span>
							<p>{CycleData.firstPart.tematPrzewodni}</p>
						</div>
						<div className="cycle-row">
							<span>prowadzenie</span>
							<p>{CycleData.firstPart.prowadzenie}</p>
						</div>
					</div>
					<div className="mastermind-plus-spacer relative">
						<div className="bg-white px-2 py-1 text-black font-semibold rounded-md w-fit absolute right-[-1px] top-1/2 translate-y-[-50%] text-xs">
							{CycleData.secondPart.duration}
						</div>
					</div>
					<div className="flex flex-col gap-5">
						<div className="cycle-row">
							<span className="relative">
								temat przewodni
								<div
									className={`px-4 py-1 text-white font-semibold rounded-md w-fit absolute right-[5px] translate-x-[100%] top-[-30px] translate-y-[-50%] text-xs uppercase tracking-widest`}
									style={{
										backgroundColor: mainColor,
									}}>
									Część 2
								</div>
							</span>
							<p>{CycleData.secondPart.tematPrzewodni}</p>
						</div>
						<div className="cycle-row">
							<span>prowadzenie</span>
							<p>{CycleData.secondPart.prowadzenie}</p>
						</div>
					</div>
					<div className="mastermind-plus-spacer"></div>
					<div className="flex flex-col">
						<div className="cycle-row">
							<span>kiedy</span>
							<p>{CycleData.kiedy}</p>
						</div>
						<div className="cycle-row">
							<span>obszar</span>
							<p>{CycleData.obszar}</p>
						</div>
						<div className="cycle-row">
							<span>lokalizacja</span>
							<p>{CycleData.lokalizacja}</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
