import React, { useState } from 'react';
import DualPhoto from '../../images/referencje-wspolne.png';
import JustynaKrzyzanowskaRef from '../../images/references/justynakrzyzanowska.png';
import JustynaKrzyzanowska from '../../images/references/profile-pics/justynakrzyzanowska.jpg';
import UrszulaWysocka from '../../images/references/profile-pics/urszulawysocka.jpg';
import KarolBielecki from '../../images/references/profile-pics/karolbielecki.jpg';
import AleksandraDezakowska from '../../images/references/profile-pics/aleksandradezakowska.jpg';
import AleksandraDezakowskaRef1 from '../../images/references/aleksandradezakowska-1.png';
import AleksandraDezakowskaRef2 from '../../images/references/aleksandradezakowska-2.png';
import MalgorzataNowak from '../../images/references/profile-pics/MałgorzataNowakNiedzwiedzka.jpeg';
import MateuszKubiczek from '../../images/references/profile-pics/MateuszKubiczek.jpeg';
import AleksandraZazdrozna from '../../images/references/profile-pics/AleksandraZadrozna.jpeg';
import LukaszBulatek from '../../images/references/profile-pics/LukaszBulatek.jpeg';
import MalgorzataNowakRef from '../../images/references/malgorzatanowak.jpg';
import MateuszKubiczekRef from '../../images/references/mateuszkubiczek.jpg';
import AleksandraZazdroznaRef from '../../images/references/aleksandrazazdrozna.jpg';
import LukaszBulatekRef1 from '../../images/references/lukaszbulatek-1.jpg';
import LukaszBulatekRef2 from '../../images/references/lukaszbulatek-2.jpg';
import BarbaraNiedzwiedzka from '../../images/references/profile-pics/barbaraniedzwiedzka.jpg';
import BarbaraNiedzwiedzkaRef from '../../images/references/barbaraniedzwiedzka.png';
import AnnaDyakowska from '../../images/references/profile-pics/annadyakowska.jpeg';
import AnnaDyakowskaRef from '../../images/references/annadyakowska.jpg';
import PiotrZajac from '../../images/references/profile-pics/piotrzajac.jpg';
import NataliaMiller from '../../images/references/profile-pics/nataliamiller.jpg';
import MajaBazan from '../../images/references/profile-pics/majabazan.jpg';
import RobertProczko from '../../images/references/profile-pics/robertproczko.jpg';
import JoannaOstrowska from '../../images/references/profile-pics/joannaostrowska.jpg';

type Props = {};

export default function References({}: Props) {
	const [imageToDisplay, setImageToDisplay] = useState<React.ReactElement>();

	function handleReferenceClick(e: React.MouseEvent) {
		const target: EventTarget & Element = e.currentTarget;
		const attr: string | null = target.getAttribute('data-reference');
		if (!attr) return;

		const index: number = parseInt(attr);

		switch (index) {
			case 1: {
				setImageToDisplay(<img src={MalgorzataNowakRef} />);
				break;
			}
			case 2: {
				setImageToDisplay(<img src={MateuszKubiczekRef} />);
				break;
			}
			case 3: {
				setImageToDisplay(<img src={JustynaKrzyzanowskaRef} />);
				break;
			}

			case 6: {
				setImageToDisplay(<img src={AleksandraZazdroznaRef} />);
				break;
			}

			case 7: {
				setImageToDisplay(
					<>
						<img src={AleksandraDezakowskaRef1} />
						<img src={AleksandraDezakowskaRef2} />
					</>
				);
				break;
			}

			case 8: {
				setImageToDisplay(
					<>
						<img src={LukaszBulatekRef1} />
						<img src={LukaszBulatekRef2} />
					</>
				);
				break;
			}

			case 9: {
				setImageToDisplay(<img src={BarbaraNiedzwiedzkaRef} />);
				break;
			}
		}
		document.getElementById('image--displayer')?.classList.add('active');
		document.getElementById('overlay')?.classList.add('active');
	}

	function handleCloseImageDisplay() {
		const overlay = document.getElementById('overlay');
		overlay?.classList.remove('active');
		document.getElementById('image--displayer')?.classList.remove('active');
		setImageToDisplay(undefined);
	}

	return (
		<main className='my-5 w-[90%] mx-auto site--content'>
			<div id='image--displayer' onClick={handleCloseImageDisplay}>
				{imageToDisplay}
			</div>
			<div id='overlay' onClick={handleCloseImageDisplay}></div>
			<div className='flex justify-between items-center flex-col gap-[100px]'>
				<div className='flex flex-col gap-5 items-center'>
					<span className='uppercase tracking-[3px] text-xl'>REFERENCJE</span>
					<img src={DualPhoto} className='w-[270px]' />
					<div className='tracking-[3px] uppercase text-xs font-bold flex flex-col gap-2 items-center'>
						<span>TOMASZ DALACH</span>
						<span>ALEKSANDRA ŚLIFIRSKA</span>
					</div>
				</div>
				<div className='reference-grid'>
					<div className='reference'>
						<div className='flex items-center gap-3'>
							<img src={MalgorzataNowak} />
							<div className='flex flex-col'>
								<p>Małgorzata Nowak-Niedźwiedzka</p>
								<p>Wiceprezes Zarządu</p>
								<p>POLDENT</p>
							</div>
						</div>
						<p className='mt-5 font-medium'>
							<i>
								„Czasy się zmieniają, firma rośnie, wiedza „plemienna” już nie wystarcza, istnieje
								potrzeba bardziej systemowego podejścia do wielu zagadnień. M.in. z powyższych
								powodów, ponad 2 lata temu, rozpoczęliśmy współpracę z panem Tomaszem Dalachem.
								Przez cały czas korzystamy z jego dużej wiedzy biznesowej i wieloletniego
								doświadczenia jako mentora. To pozwala naszej firmie przejść na wyższy poziom
								funkcjonowania, lepsze planowanie pracy, bardziej efektywne pokonywanie barier.
								Razem tworzymy strategie, pozwalające na szybszy rozwój firmy.”
							</i>
						</p>
						<p
							className='flex w-full justify-start mt-5 cursor-pointer whole--reference'
							onClick={handleReferenceClick}
							data-reference={1}>
							Pełna treść referencji:
						</p>
					</div>
					<div className='reference'>
						<div className='flex items-center gap-3'>
							<img src={PiotrZajac} />
							<div className='flex flex-col'>
								<p>Piotr Zając</p>
								<p>CEO Untitled Kingdom</p>
							</div>
						</div>
						<p className='mt-5 font-medium'>
							<i>
								„W realizacji strategii biznesowej komunikacja lidera jest bardzo ważna. Kiedy
								poprosiłem po raz pierwszy Aleksandrę Ślifirską o pomoc w przygotowaniu komunikacji
								dotyczącej misji i wizji mojej firmy, nie spodziewałem się, że ta współpraca wpłynie
								na mnie w takim stopniu. Przez te ostatnie kilkanaście miesięcy nauczyłem się więcej
								o komunikacji lidera, niż z jakiejkolwiek książki, którą przeczytałem. Podczas
								naszych spotkań, zawsze zaskakuje mnie z jaką precyzją Aleksandra wskazuje problem i
								jak proste do zastosowania daje rady. Każde nasze spotkanie to dla mnie inwestycja w
								rozwój zarówno Untitled Kingdom jak i mój.”
							</i>
						</p>
					</div>

					<div className='reference'>
						<div className='flex items-center gap-3'>
							<img src={MateuszKubiczek} />
							<div className='flex flex-col'>
								<p>Mateusz Kubiczek</p>
								<p>Prezes Zarządu</p>
								<p>THE SOFTWARE HOUSE</p>
							</div>
						</div>
						<p className='mt-5 font-medium'>
							<i>
								„Łącząc to wszystko razem, działamy w sposób bardziej przemyślany i zaplanowany, a
								duch zespołu, który obudził się podczas tych spotkań, pomógł nam przejść przez
								trudny czas pandemii z najlepszym w historii wynikiem finansowym. Obecny rok, 2022,
								zakończymy mając 300 osób i wielu nowych klientów na pokładzie, wiedząc, że to
								właśnie Tomek pomógł nam sobie ten cel postawić i zmotywował nas, by do niego
								dążyć.”
							</i>
						</p>
						<p
							className='flex w-full justify-start mt-5 cursor-pointer whole--reference'
							onClick={handleReferenceClick}
							data-reference={2}>
							Pełna treść referencji:
						</p>
					</div>
					<div className='reference'>
						<div className='flex items-center gap-3'>
							<img src={NataliaMiller} />
							<div className='flex flex-col'>
								<p>Natalia Miller</p>
								<p>Customs Operations Poland Manager w Volvo Group</p>
							</div>
						</div>
						<p className='mt-5 font-medium'>
							<i>
								„Miałam niewątpliwą przyjemność uczestniczyć w szkoleniu "Personal Branding"
								prowadzonym przez Panią Olę. Dlaczego przyjemność? Po pierwsze Pani Ola posiada
								ogromną wiedzę na temat budowania marki własnej i, co równie ważne, potrafi ją
								skutecznie przekazać. Po drugie atmosfera wprowadzona przez Panią Olę bardzo
								pozytywnie wpływała na pracę i zaangażowanie grupy. Po trzecie, i chyba
								najważniejsze, szkolenie stanowiło dla mnie „a real eye-opener” czyli objawienie -
								dzięki wprowadzeniu w życie tylko kilku z wielu przekazanych wskazówek udało mi się
								wprowadzić kilka usprawnień do swojej działalności. Szkolenie i samo spotkanie z
								Panią Olą spowodowało olbrzymi przypływ dobrej energii, która jest ze mną do dziś.
								Pani Olu - dziękuję!”
							</i>
						</p>
					</div>
					<div className='reference'>
						<div className='flex items-center gap-3'>
							<img src={JustynaKrzyzanowska} />
							<div className='flex flex-col'>
								<p>Justyna Krzyżanowska</p>
								<p>Category Manager</p>
								<p>BLACK RED WHITE</p>
							</div>
						</div>
						<p className='mt-5 font-medium'>
							<i>
								„Wiedza, którą wyciągnęłam z mentoringu była tak obszerna i szeroka, iż
								potrzebowałam czasu, aby ją w pełni przyswoić i wdrożyć w życie. Nasze spotkania na
								długo zostaną w mojej pamięci, gdyż były moją drogą ku samoświadomości i odkryciu
								lepszej wersji siebie. Pozwoliły mi pozbyć się swoich ograniczeń i skupić na
								wewnętrznej sile, którą każdy z Nas w sobie ma tylko nie zawsze potrafi ją dostrzec.
								Polecam współpracę z Tomkiem z pełnym przekonaniem, że jest to najlepsza inwestycja
								w siebie!”.
							</i>
						</p>
						<p
							className='flex w-full justify-start mt-5 cursor-pointer whole--reference'
							onClick={handleReferenceClick}
							data-reference={3}>
							Pełna treść referencji:
						</p>
					</div>
					<div className='reference'>
						<div className='flex items-center gap-3'>
							<img src={MajaBazan} />
							<div className='flex flex-col'>
								<p>Maja Bazan-Trojańska</p>
								<p>Head of Learning & Development, Raisin DS, Berlin</p>
							</div>
						</div>
						<p className='mt-5 font-medium'>
							<i>
								„Miałam przyjemność współpracować z Aleksandrą w 2021 r. w trakcie indywidualnego
								"coachsulting" dotyczącego budowania marki osobistej. Sesje były dla mnie bardzo
								odkrywcze oraz inspirujące. W trakcie procesu Aleksandra była cały czas zaangażowana
								i elastyczna, a jej umiętności aktywnego słuchania zasługują na szczególne
								podkreślenie. Na tej podstawie udało nam się wypracować szyte na miarę rozwiązania,
								które do dzisiaj stosuję w mojej pracy. Szczególnie cenię sobie praktyczność metod
								pracy oraz osadzenie ich w realiach biznesowych, dzięki temu wspólnie stworzony plan
								działania procentuje do dzisiaj. Serdecznie polecam Aleksandrę jako doświadczonego
								eksperta-praktyka a także empatycznego coacha.” 
							</i>
						</p>
					</div>
					<div className='reference'>
						<div className='flex items-center gap-3'>
							<img src={BarbaraNiedzwiedzka} />
							<div className='flex flex-col'>
								<p>Barbara Niedźwiedzka-Pająk</p>
								<p>Członek Zarządu, Dyrektor Marketingu</p>
								<p>POLDENT</p>
							</div>
						</div>
						<p className='mt-5 font-medium'>
							<i>
								„Od samego początku wiedza i doświadczenie Tomka pozwoliły nam spojrzeć na
								organizację firmy z innej perspektywy. Spotkania nie były łatwe ze względu mierzenie
								się przez nas ze status quo. Tomek sprawiał, że dyskusje jednak zawsze były
								merytoryczne, a poruszane przez niego tematy inspirujące (…). Rekomenduję Tomasza
								Dalacha jako Mentora właścicieli, prezesów, menedżerów, liderów i rozwoju całych
								organizacji!”
							</i>
						</p>
						<p
							className='flex w-full justify-start mt-5 cursor-pointer whole--reference'
							onClick={handleReferenceClick}
							data-reference={9}>
							Pełna treść referencji:
						</p>
					</div>
					<div className='reference'>
						<div className='flex items-center gap-3'>
							<img src={RobertProczko} />
							<div className='flex flex-col'>
								<p>Robert Proczko</p>
								<p>Biegły Sądowy | Inżynier budownictwa Członek zwyczajny SIDIR</p>
							</div>
						</div>
						<p className='mt-5 font-medium'>
							<i>
								„Miałem ogromną przyjemność pracować z Olą. To profesjonalistka mająca ogromne
								doświadczenie i wiedzę zarówno teoretyczną jak i praktyczną. Z całą pewnością jest
								najlepszym ekspertem komunikacji jakiegokolwiek dotąd spotkałem. Otwarta,
								komunikatywna, dostrzegająca zalety jak i wady klienta. Potrafi szybko zdiagnozować
								sytuację i znaleźć rozwiązanie. Z pewnością lubi wyzwania i trudne sytuacje
								wymagające szczególnego zaangażowania i nieszablonowych rozwiązań.”
							</i>
						</p>
					</div>
					<div className='reference'>
						<div className='flex items-center gap-3'>
							<img src={AnnaDyakowska} />
							<div className='flex flex-col'>
								<p>Anna Dyakowska</p>
								<p>PROJEKTANTKA GRAFICZNA</p>
							</div>
						</div>
						<p className='mt-5 font-medium'>
							<img className='dyakowskaimg' src={AnnaDyakowskaRef} />
						</p>
					</div>
					<div className='reference'>
						<div className='flex items-center gap-3'>
							<img src={UrszulaWysocka} />
							<div className='flex flex-col'>
								<p>Urszula Wysocka</p>
								<p>BANKING AND FINANCE EXPERT</p>
							</div>
						</div>
						<p className='mt-5 font-medium'>
							<i>
								„Z Tomkiem znamy się od 10 lat. Jesteśmy świadkami naszych dróg rozwoju. Tomek jest
								osobą, która inspiruje oraz wskazuje nowe kierunki działania, myślenia. Chętnie
								dzieli się swoim doświadczeniem. To On pokazał mi, że będąc mentorem warto też być
								mentee. Dzięki rozmowom z Tomkiem przekonałam się, że nasz rozwój nigdy się nie
								kończy, a nasze doświadczenia często mobilizują nas do wyjścia ze swojej strefy
								komfortu, co może być drogą do sukcesu. Rekomenduję Tomka jako Mentora Liderów,
								którzy chcą stawić czoła każdemu wyzwaniu.”
							</i>
						</p>
					</div>
					<div className='reference'>
						<div className='flex items-center gap-3'>
							<img src={JoannaOstrowska} />
							<div className='flex flex-col'>
								<p>Joanna Ostrowska</p>
								<p>Dyr. zarządzająca siecią Osti-hotele</p>
							</div>
						</div>
						<p className='mt-5 font-medium'>
							<i>
								„Z pełnym przekonaniem polecam Aleksandrę Ślifirską jako doświadczonego mentora w
								procesie budowania marki osobistej. Łączy ona skutecznie dwie perspektywy:
								perspektywę skutecznego budowania relacji lidera z zespołami oraz tworzenia profilu
								publicznego, który zdecydowanie ułatwia nawiązywanie kluczowych relacji biznesowych.
								Praca z Olą była i jest dla mnie narzędziem rozwoju osobistego, co przekłada się
								także na rozwój mojego biznesu. Szczególnie ważne dla mnie w tym procesie jest
								możliwość odbywania rozmów rozwojowych, które ukierunkowują moje procesy decyzyjne i
								umożliwiają lepsze porozumiewanie się z ludźmi osobiście, a także poprzez media.”
							</i>
						</p>
					</div>
					<div className='reference'>
						<div className='flex items-center gap-3'>
							<img src={KarolBielecki} />
							<div className='flex flex-col'>
								<p>Karol Bielecki</p>
								<p>Prezes Zarządu</p>
								<p>ACGROUP</p>
							</div>
						</div>
						<p className='mt-5 font-medium'>
							<i>
								„Wiedza to kapitał. Ocean Capital inspiruje i poszerza horyzonty, co daje impuls do
								działania oraz wdrażania nowych idei we własnym środowisku biznesowym.”
							</i>
						</p>
					</div>
					<div className='reference'>
						<div className='flex items-center gap-3'>
							<img src={AleksandraZazdrozna} />
							<div className='flex flex-col'>
								<p>Aleksandra Zadrożna</p>
								<p>Kierownik Działu Szkoleń</p>
								<p>POLDENT</p>
							</div>
						</div>
						<p className='mt-5 font-medium'>
							<i>
								„Naszą pracę mogę porównać do stopni, które pokonuję każdego dnia. Kiedy trafiam na
								stopień, który “kosztuje” mnie więcej emocji, pracy i pojawiają się chwile zawahania
								wiem, że Tomek nie pozwoli mi spaść i będę mogła liczyć na jego wsparcie. Z czasem
								poczułam, że pokonywanie kolejnych stopni staje się dla mnie coraz łatwiejsze, a
								coraz więcej z nich pokonuję samodzielnie. Postęp, który obserwuję przynosi mi
								satysfakcję i motywację do działania. Chcę budować na tym dalszy swój rozwój oraz
								rozwój ludzi, za których jestem odpowiedzialna”.
							</i>
						</p>
						<p
							className='flex w-full justify-start mt-5 cursor-pointer whole--reference'
							onClick={handleReferenceClick}
							data-reference={6}>
							Pełna treść referencji:
						</p>
					</div>
					<div className='reference'>
						<div className='flex items-center gap-3'>
							<img src={AleksandraDezakowska} />
							<div className='flex flex-col'>
								<p>Aleksandra Deżakowska</p>
								<p>Lekarz dentysta, przedsiębiorca i startuper</p>
								<p>HD-DENTAL CENTRUM STOMATOLOGII, DENT'S PLAY</p>
							</div>
						</div>
						<p className='mt-5 font-medium'>
							<i>
								„Proces rozwoju, w którym uczestniczyłam z Tomkiem, pozwolił mi na poukładanie
								procesów i założeń nowej firmy, uniknięcie wielu błędów, a przede wszystkim dał mi
								możliwość korzystania z obszernej wiedzy i doświadczeń Tomka w każdej z dziedzin,
								nad którą pracowaliśmy. Gdyby nie ta współpraca, wszystko trwałoby o wiele dłużej,
								byłoby dla mnie o wiele większym obciążeniem emocjonalnym. Największą wartością tych
								kilku miesięcy było dla mnie możliwość podzielenia się swoimi wątpliwościami i
								obawami z osobą o wiele większym przygotowaniu biznesowym niż ja. A wszystko to - w
								atmosferze pełnej zaufania i akceptacji.”
							</i>
						</p>
						<p
							className='flex w-full justify-start mt-5 cursor-pointer whole--reference'
							onClick={handleReferenceClick}
							data-reference={7}>
							Pełna treść referencji:
						</p>
					</div>
					<div className='reference'>
						<div className='flex items-center gap-3'>
							<img src={LukaszBulatek} />
							<div className='flex flex-col'>
								<p>Łukasz Bułatek</p>
								<p>Customer Relations Manager</p>
								<p>POLDENT</p>
							</div>
						</div>
						<p className='mt-5 font-medium'>
							<i>
								„Bardzo ważną zmianą w moim życiu i karierze było pojawienie się w firmie Tomka
								Dalacha, który od kilku miesięcy jest moim mentorem, a współpraca trwa nadal. Ten
								czas zmienił moją osobę, moje spojrzenie na otaczający mnie świat i ludzi, moje
								życie i moją pracę. Tomka mentoring pozwolił mi wybrać drogę, którą chcę podążać,
								aby osiągnąć sukces osobisty i aby zmieniać to, co wymaga zmiany na lepsze.
								Doświadczyłem dzięki Tomkowi swojej wielkiej przemiany osobistej.”
							</i>
						</p>
						<p
							className='flex w-full justify-start mt-5 cursor-pointer whole--reference'
							onClick={handleReferenceClick}
							data-reference={8}>
							Pełna treść referencji:
						</p>
					</div>
				</div>
			</div>
		</main>
	);
}
