import React, { useEffect, useRef, useState } from 'react';
import MastermindIcon from '../../images/icons/mastermind.svg';
import Logo from '../../images/logo_white.png';
import { Link, useLocation } from 'react-router-dom';
import People from '../../images/mastermind_people.png';
import { MastermindPlusProps, mastermindTexts, mastermindTitles } from '../mastermind-texts';
import ReactMarkdown from 'react-markdown';
import MarkdownRenderer from '../desktop_tablet/MastermindPlusPages/MarkdownRenderer';
import MastermindPlusFooter from './Footers/MastermindPlusFooter';
import CykleSpotkan from './MastermindPlusPages/CykleSpotkan';
import PartnerzyProgramu from './MastermindPlusPages/PartnerzyProgramu';
import Zaloga from './MastermindPlusPages/Zaloga';

type Props = {};

export default function Mastermind({}: Props) {
	const [insightsClicked, setInsightsClicked] = useState<boolean>(false);

	const [currentPage, setCurrentPage] = useState<number>(0); // zero based
	const [currentChildrenPage, setCurrentChildrenPage] = useState<number>(0); // also zero based, it holds the value for currently expanded list

	const [currentComponent, setCurrentComponent] = useState<React.JSX.Element>();

	const [isRenderingMarkdown, setIsRenderingMarkdown] = useState<boolean>(false);

	const [circleColor, setCircleColor] = useState<string>('');

	function handleDotClick(e: React.MouseEvent<HTMLDivElement>) {
		const id: number = parseInt(e.currentTarget.id);

		document.querySelectorAll<HTMLDivElement>('.mastermind-circles .circle').forEach((circle: HTMLDivElement) => {
			if (circle.id !== 'circle-' + id) {
				circle.classList.remove('active');
			}
		});

		document.getElementById('circle-' + id)?.classList.toggle('active');
	}

	function handleInsightsClick() {
		if (!insightsClicked) {
			setTimeout(() => {
				document.querySelector('.mastermind-plus-background')?.classList.add('active');
			}, 400);
		} else {
			document.querySelector('.mastermind-plus-background')?.classList.remove('active');
		}
		setInsightsClicked((prev) => !prev);
	}

	useEffect(() => {
		document.querySelector<HTMLDivElement>('.site--content')?.style.setProperty('--actualViewportHeight', `${window.innerHeight}px`);

		return () => {
			setInsightsClicked(false);
			setCurrentPage(0);
		};
	}, []);

	useEffect(() => {
		document.querySelectorAll('.circle').forEach((circle) => {
			circle.classList.remove('active');
		});
		document.querySelectorAll('.circle-small').forEach((circle) => {
			circle.classList.toggle('hidden', insightsClicked);
		});
		document.querySelector('.mastermind-content-displayer')?.classList.toggle('active', insightsClicked);
	}, [insightsClicked]);

	useEffect(() => {
		document.querySelector('.mastermind-text-holder')?.scrollTo({
			top: 0,
			left: 0,
		});

		setIsRenderingMarkdown(false);

		if (currentPage >= 4 && currentPage <= 12) {
			setCurrentComponent(<CykleSpotkan meetingNumber={currentChildrenPage - currentPage} mainColor={circleColor} />);
			setCircleColor(MastermindPlusProps.mastermindMeetingCycleColors.at(currentChildrenPage - currentPage) || 'pink');
		} else {
			setCircleColor(MastermindPlusProps.mastermindPlusColors.at(currentPage) || 'pink');

			switch (currentPage) {
				case 0: {
					setCurrentComponent(<PartnerzyProgramu />);
					break;
				}
				case 3: {
					setCurrentComponent(<Zaloga />);

					break;
				}

				default: {
					setCurrentComponent(<MarkdownRenderer text={MastermindPlusProps.mastermindPlusTexts[currentPage]} h2Classes="font-medium" />);
					setIsRenderingMarkdown(true);
					break;
				}
			}
		}
	}, [currentPage, currentChildrenPage, circleColor]);

	return (
		<>
			<div className="mastermind-content site--content">
				<div className="mastermind-icon " onClick={handleInsightsClick}>
					<div className="relative">
						<img src={MastermindIcon} className="w-[100px]" />

						<div className="circle-small bottom-[-3px] right-[-5px] opacity-50 forth"></div>
					</div>
				</div>
				{!insightsClicked && (
					<Link to="/" className="cursor-pointer absolute z-[300] bottom-6 left-4">
						<img src={Logo} className="w-[100px]" />
					</Link>
				)}

				<div className="mastermind-circles">
					<div className="relative">
						<div className="circle" id="circle-1">
							<div>
								<p className="tracking-[2px] text-xl leading-loose">
									Strategiczny MASTERMIND + <br /> Zdefiniuj siebie na nowo jako liderkę lub lidera
								</p>
								<span className="font-bold opacity-50">EDYCJA 2024/25</span>
							</div>
						</div>
						<div className="circle-small first" id="1" onClick={handleDotClick}></div>
					</div>
					<div className="relative">
						<div className="circle" id="circle-2"></div>
					</div>
					<div className="relative">
						<div className="circle" id="circle-3"></div>
					</div>
					<div className="relative">
						<div className="circle" id="circle-4"></div>
					</div>
				</div>

				<div className="mastermind-plus-background z-[100]">
					<div
						id="circle-small"
						style={{
							background: `conic-gradient(from 180deg at 50% 50%, rgba(217, 217, 217, 0) 0deg, ${circleColor}  360deg)`,
						}}></div>
					<div
						id="circle-big"
						style={{
							background: `conic-gradient(from 180deg at 50% 50%, ${circleColor}  0deg, rgba(217, 217, 217, 0) 360deg)`,
						}}></div>
				</div>

				<div className="mastermind-content-displayer text-white flex justify-between">
					{isRenderingMarkdown ? (
						<div className="flex flex-col gap-10 max-h-[65vh] overflow-y-auto leading-[34px] mastermind-text-holder">{currentComponent}</div>
					) : (
						<div className="flex flex-col gap-10 max-h-[65vh] overflow-y-auto leading-[34px] mastermind-component-holder pr-5">
							{currentComponent}
						</div>
					)}
				</div>
			</div>
			<MastermindPlusFooter
				mastermindPage={currentPage}
				setMastermindPage={setCurrentPage}
				currentChildrenPage={currentChildrenPage}
				setCurrentChildrenPage={setCurrentChildrenPage}
				insightsClicked={insightsClicked}
			/>
		</>
	);
}
