import React from 'react';
import ReactMarkdown from 'react-markdown';

type Props = {
	text: string;
	h2Classes?: string;
};

export default function MarkdownRenderer({ text, h2Classes }: Props) {
	return (
		<ReactMarkdown
			components={{
				em: (props) => {
					return <em className="tracking-[2px]">{props.children}</em>;
				},
				h1: (props) => {
					return <h1 className="tracking-[2px] text-2xl font-extralight uppercase">{props.children}</h1>;
				},
				h2: (props) => {
					return <h2 className={`tracking-[2px] text-xl font-extralight uppercase contents ${h2Classes}`}>{props.children}</h2>;
				},
				p: (props) => {
					return <p className="tracking-[1px] font-extralight">{props.children}</p>;
				},
				a: (props) => {
					return (
						<a href={props.href} target="_blank" className="font-medium">
							{props.children}
						</a>
					);
				},
			}}>
			{text}
		</ReactMarkdown>
	);
}
