import React, { ReactNode } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header';
import ForWhom from './ForWhom';
import Values from './ValuesPage';
import Mentoring from './Mentoring';
import Measure from './Measure';
import TDInfo from './TD';
import ASInfo from './AS';
import MGInfo from './MG';
import NotFound from './NotFound';
import PrivacyPolicy from './PrivacyPolicy';
import ContactForm from './ContactForm';
import ContactPage from './ContactPage';
import ScheduleMeeting from './ScheduleMeeting';
import References from './References';
import Mastermind from './Mastermind';
import MastermindPlus from './MastermindPlus';

type Props = {
	children?: ReactNode;
};

export default function Routing({ children }: Props) {
	return (
		<Router>
			<Header></Header>
			{children}
			<Routes>
				<Route path="/" element={<Values />}></Route>
				<Route path="/kontakt/formularz" element={<ContactForm />}></Route>
				<Route path="/kontakt/umow-spotkanie" element={<ScheduleMeeting />}></Route>

				<Route path="/kontakt" element={<ContactPage />}></Route>

				<Route path="/mentoring" element={<Mentoring />}>
					<Route path="tomasz-dalach" element={<TDInfo />}></Route>
					<Route path="aleksandra-slifirska" element={<ASInfo />}></Route>
					<Route path="mieczyslaw-grudzinski" element={<MGInfo />}></Route>
				</Route>

				<Route path="/polityka-prywatnosci" element={<PrivacyPolicy />}></Route>
				<Route path="/dla-kogo" element={<ForWhom />}></Route>
				<Route path="/mastermind" element={<Mastermind />}></Route>
				<Route path="/na-miare" element={<Measure />}></Route>
				<Route path="/referencje" element={<References />}></Route>

				<Route path="/mastermind-plus" element={<MastermindPlus />} />

				<Route path="*" element={<NotFound />} />
			</Routes>
		</Router>
	);
}
