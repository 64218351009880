import React from 'react';
import EmbaUW from '../../../images/mastermind-plus/emba_uw.png';
import Emba from '../../../images/mastermind-plus/emba.png';
import WydzialZarzadzania from '../../../images/mastermind-plus/wydzial_zarzadzania.png';
import KawkaZDudkiem from '../../../images/mastermind-plus/kawka_z_dudkiem.png';
import MCZUW from '../../../images/mastermind-plus/mcz_uw.png';

type Props = {};

export default function PartnerzyProgramu({}: Props) {
	return (
		<div className="grid grid-cols-2 grid-flow-row mt-48">
			<img src={WydzialZarzadzania} className="h-20" />
			<img src={EmbaUW} className="h-20" />
			<img src={Emba} className="h-20" />
			<img src={KawkaZDudkiem} className="h-20" />
			<img src={MCZUW} className="h-20" />
		</div>
	);
}
