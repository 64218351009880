export const meetingOptions: MeetingItem[] = [
	{ type: 'checkbox', label: 'Mentoringiem 1:1', propertyName: 'mentoring1Na1' },
	{ type: 'checkbox', label: 'Mentoringiem zespołu', propertyName: 'mentoringZespolu' },
	{ type: 'checkbox', label: 'Udziałem w grupie Mastermind', propertyName: 'udzialWMastermind' },
	{
		type: 'checkbox',
		label: 'Organizacją grupy Mastermind w Twojej organizacji',
		propertyName: 'organizacjaMastermindDlaMnie',
	},
	{ type: 'checkbox', label: 'Konsultacjami biznesowymi', propertyName: 'konsultacjeBiznesowe' },
	{
		type: 'checkbox',
		label: 'Czy korzystałaś/korzystałeś dotąd z mentoringu?',
		propertyName: 'korzystalemZMentoringu',
		bold: true,
	},
	{
		type: 'checkbox',
		label: 'Czy brałaś/brałeś dotąd udział w grupie Mastermind?',
		propertyName: 'korzystalemZMastermind',
		bold: true,
	},
	{
		type: 'numberinput',
		label: 'Ile osób pracuje w Twojej organizacji? Podaj liczbę osób (może być w przybliżeniu)',
		propertyName: 'ileOsobPracuje',
		bold: true,
	},
	{
		type: 'input',
		label: 'Czego oczekujesz od współpracy z OCEAN CAPITAL?',
		propertyName: 'czegoOczekuje',
		bold: true,
	},
];

export interface MeetingItem {
	type: 'checkbox' | 'input' | 'none' | 'numberinput';
	label: string;
	propertyName: string;
	bold?: boolean;
}

export interface MeetingChoices {
	[key: string]: any;
	fullName: string;
	email: string;
	phoneNumber: string;
	company: string;
	mentoring1Na1: boolean;
	mentoringZespolu: boolean;
	udzialWMastermind: boolean;
	organizacjaMastermindDlaMnie: boolean;
	konsultacjeBiznesowe: boolean;
	korzystalemZMentoringu: boolean;
	korzystalemZMastermind: boolean;
	ileOsobPracuje: number;
	czegoOczekuje: string;
}

export const emptyMeetingChoices: MeetingChoices = {
	fullName: '',
	email: '',
	phoneNumber: '',
	company: '',
	mentoring1Na1: false,
	mentoringZespolu: false,
	udzialWMastermind: false,
	organizacjaMastermindDlaMnie: false,
	konsultacjeBiznesowe: false,
	korzystalemZMentoringu: false,
	korzystalemZMastermind: false,
	ileOsobPracuje: 0,
	czegoOczekuje: '',
};
