import React from 'react';
import Aleksandra from '../../../images/mastermind-plus/aleksandra.png';
import TD from '../../../images/mastermind-plus/tomasz_dalach.png';
import Mieczyslaw from '../../../images/mastermind-plus/mieczyslaw.png';
import Ludwicki from '../../../images/mastermind-plus/tomasz_ludwicki.png';
import Pawel from '../../../images/mastermind-plus/pawel_dudek.png';
import Jan from '../../../images/mastermind-plus/Jan Maria Kowalski.png';

type Props = {};

interface Ekspert {
	img: any;
	name: string;
	desc: string;
}

export default function Zaloga() {
	const people: Ekspert[] = [
		{
			img: Aleksandra,
			name: 'Aleksandra Ślifirska',
			desc: `Przedsiębiorczyni i właścicielka biznesów, wieloletnia trenerka reputacji i marki osobistej top liderów, autorka książek “Rekiny Biznesu w mediach” oraz “Medialne lwy dla rekinów biznesu”.`,
		},
		{
			img: Ludwicki,
			name: 'dr. Tomasz Ludwicki',
			desc: `Doradca właścicieli i zarządów w obszarze budowania i wdrażania strategii firm oraz zarządzania procesami organizacyjnymi, dyrektor programów i wykładowca Executive MBA Wydziału Zarządzania Uniwersytetu Warszawskiego.`,
		},
		{
			img: Mieczyslaw,
			name: 'prof. Mieczysław Grudziński',
			desc: `Doradca właścicieli i zarządów z zakresu zarządzania finansami i strategii firm, profesor i wykładowca polskich i zagranicznych uniwersytetów, zdobywca wielu wyróżnień dla najlepszego wykładowcy MBA.`,
		},
		{
			img: TD,
			name: 'Tomasz Dalach, MBA',
			desc: `Twórca, właściciel i lider biznesów, przedsiębiorca, wykładowca Executive MBA Wydziału Zarządzania Uniwersytetu Warszawskiego, mentor liderek i liderów, łączy wiedzę i narzędzia MBA z psychologią przywództwa.`,
		},
		{
			img: Pawel,
			name: 'Paweł Dudek',
			desc: `Lider międzynarodowych i polskich firm, zrealizował dotąd projekty w ponad 16 krajach, mentor, trener, wykładowca na studiach MBA, certyfikowany instruktor metodyki zarządzania zmianami PROSCI®.`,
		},
		{
			img: Jan,
			name: 'Jan Maria Kowalski, MBA',
			desc: `Lider IT zespołów badawczo- wdrożeniowych w zakresie robotyzacji, analityki danych, BigData, AI i usług chmurowych. Zaangażowany w rozwój Generatywnej Sztucznej Inteligencji w Polsce i budowę polskiego otwartego modelu językowego (Bielik.AI).`,
		},
	];

	return (
		<div className="mastermind-plus-people gap-y-20 pb-40">
			{people.map((p) => {
				return (
					<div className="flex flex-col gap-5">
						<img src={p.img} alt="person-image" className="w-[87px]" />
						<div className="flex flex-col gap-2">
							<span>{p.name}</span>
							<p>{p.desc}</p>
						</div>
					</div>
				);
			})}
		</div>
	);
}
