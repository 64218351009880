import { createTheme } from '@mui/material/styles';
import { plPL } from '@mui/material/locale';

declare module '@mui/material/styles' {
	interface Palette {
		black: Palette['primary'];
	}

	interface PaletteOptions {
		black?: PaletteOptions['primary'];
	}
}

declare module '@mui/material/TextField' {
	interface TextFieldPropsColorOverrides {
		black: true;
	}
}

declare module '@mui/material/Checkbox' {
	interface CheckboxPropsColorOverrides {
		black: true;
	}
}

export const blackTheme = createTheme(
	{
		palette: {
			black: {
				main: 'rgb(0, 0, 0)', // Main black
				dark: 'rgb(26, 26, 26)', // Darker black
				light: 'rgb(51, 51, 51)', // Lighter black
				contrastText: '#fff',
			},
		},
	},
	plPL
);
