import React from 'react';

type Props = {};

export default function MentoringInset({}: Props) {
  function handleInsetClick(e: React.MouseEvent<HTMLDivElement>) {
    const target = e.currentTarget;
    if (!target) return;

    document.querySelectorAll<HTMLDivElement>('.mentoring--inset').forEach((el: HTMLDivElement) => {
      if (el !== target) el.classList.remove('active');
    });

    target.classList.toggle('active');
  }

  return (
    <div
      className='font-semibold gap-1 mt-20 text-end flex flex-col uppercase text-xs tracking-[3.5px] self-start ml-6 mentoring--inset'
      onClick={handleInsetClick}
    >
      <label>
        Ludzie <span> NAJWAŻNIEJSI</span>
      </label>
      <label>
        Przyw&#243;dztwo <span> ODPOWIEDZIALNOŚĆ</span>
      </label>
      <label>
        Strategia <span> DOKĄD i JAK</span>
      </label>
      <label>
        Komunikacja <span> RELACJE</span>
      </label>
      <label>
        Finanse <span> NARZĘDZIA</span>
      </label>
    </div>
  );
}
