import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Ocean from '../../images/ocean_kolko.png';
import {
	MeetingChoices,
	MeetingItem,
	emptyMeetingChoices,
	meetingOptions,
} from '../meeting-options';
import { Checkbox, FormControlLabel, TextField, ThemeProvider, useTheme } from '@mui/material';
import { blackTheme } from '../globalTypes';
import axios, { AxiosResponse } from 'axios';
import ContactVideo from '../../images/contact-video.mp4';
import { IoCloseSharp } from 'react-icons/io5';

type Props = {};

export default function ScheduleMeeting({}: Props) {
	const [formFilled, setFormFilled] = useState<boolean>(false);

	useEffect(() => {
		if (formFilled) {
			const head = document.querySelector('head');
			const script = document.createElement('script');
			script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
			head?.appendChild(script);
		}
	}, [formFilled]);

	function containsNonDigitCharacters(str: string) {
		return /\D/.test(str);
	}

	function handleChange(
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		type: 'checkbox' | 'input' | 'numberinput'
	) {
		if (type === 'numberinput') {
			if (containsNonDigitCharacters(e.target.value)) return;

			setMeetingChoices({
				...meetingChoices,
				// @ts-ignore
				[e.target.name]: e.target.value,
			});
		} else {
			setMeetingChoices({
				...meetingChoices,
				// @ts-ignore
				[e.target.name]: type === 'checkbox' ? e.target.checked : e.target.value,
			});
		}
	}

	const [meetingChoices, setMeetingChoices] = useState<MeetingChoices>(emptyMeetingChoices);

	function getElementForItem(item: MeetingItem) {
		switch (item.type) {
			case 'checkbox': {
				return (
					<Checkbox
						checked={meetingChoices[item.propertyName]}
						name={item.propertyName}
						onChange={(e) => handleChange(e, 'checkbox')}
						color='black'
					/>
				);
			}
			case 'numberinput': {
				return (
					<TextField
						value={meetingChoices[item.propertyName]}
						name={item.propertyName}
						onChange={(e) => handleChange(e, 'numberinput')}
						className='w-[70px]'
						multiline
						maxRows={3}
						size='small'
						color='black'
					/>
				);
			}
			case 'input': {
				return (
					<TextField
						value={meetingChoices[item.propertyName]}
						name={item.propertyName}
						onChange={(e) => handleChange(e, 'input')}
						className='w-full'
						multiline
						maxRows={3}
						color='black'
					/>
				);
			}

			case 'none': {
				return <></>;
			}
		}
	}

	async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();

		await axios
			.post('https://api.oceancapital.pl/send_mail_scheduled_meeting', {
				email: meetingChoices.email,
				phoneNumber: meetingChoices.phoneNumber,
				fullName: meetingChoices.fullName,
				company: meetingChoices.company,
				mentoring1Na1: meetingChoices.mentoring1Na1,
				mentoringZespolu: meetingChoices.mentoringZespolu,
				udzialWMastermind: meetingChoices.udzialWMastermind,
				organizacjaMastermindDlaMnie: meetingChoices.organizacjaMastermindDlaMnie,
				konsultacjeBiznesowe: meetingChoices.konsultacjeBiznesowe,
				korzystalemZMentoringu: meetingChoices.korzystalemZMentoringu,
				korzystalemZMastermind: meetingChoices.korzystalemZMastermind,
				ileOsobPracuje: meetingChoices.ileOsobPracuje,
				czegoOczekuje: meetingChoices.czegoOczekuje,
			})
			.then((result: AxiosResponse) => {
				if (result.status === 200) {
					setFormFilled(true);
					setMeetingChoices(emptyMeetingChoices);
					rerenderForm();

					console.log('OK');
				} else {
					console.error('500 - Internal server error');
				}
			})
			.catch((err) => {
				console.error(err);
				console.error('error while sending');
			});
	}

	const [renderedForm, setRenderedForm] = useState<React.ReactNode[]>([]);
	function rerenderForm() {
		setRenderedForm(
			meetingOptions.map((x: MeetingItem) => {
				return (
					<li
						className={`${
							x.type === 'input'
								? 'flex-col items-start gap-[10px]'
								: 'justify-between items-center gap-5'
						} ${x.type === 'numberinput' ? 'quantity-input' : ''}`}>
						<span className={`${x.bold ? 'font-bold' : ''}`}>{x.label}</span>
						{getElementForItem(x)}
					</li>
				);
			})
		);
	}

	useEffect(() => {
		rerenderForm();
	}, [meetingChoices]);

	const [videoShown, setVideoShown] = useState<boolean>(false);

	function handleShowVideo() {
		document.getElementById('overlay')?.classList.add('active');
		setVideoShown(true);
	}

	function handleCloseVideo() {
		document.getElementById('overlay')?.classList.remove('active');
		setVideoShown(false);
	}

	return (
		<ThemeProvider theme={blackTheme}>
			<div id='overlay' onClick={handleCloseVideo}></div>
			<div className='pb-20 w-[90%] mx-auto mt-[40px] mb-[200px] schedule-meeting-form flex flex-col site--content'>
				{videoShown && (
					<div id='contact-video'>
						<video autoPlay={true}>
							<source
								src='https://oceancapital-updated-content.s3.eu-west-1.amazonaws.com/media/contact-video.mp4'
								type='video/mp4'
							/>
						</video>
						<span
							onClick={handleCloseVideo}
							className='text-white font-medium text-xl cursor-pointer border rounded-[5px] px-2 py-1 bg-slate-500'>
							<IoCloseSharp size={20} />
						</span>
					</div>
				)}
				<div className='relative meeting-img-holder'>
					<img
						src={Ocean}
						className='rounded-full w-[200px] h-[200px] mx-auto mb-10'
						onClick={handleShowVideo}
					/>
					<span>kliknij</span>
					<label>obejrzyj</label>
				</div>

				<div className='flex flex-col gap-10 items-center'>
					<h1 className='text-[22px] tracking-[3px] contact-title text-center'>
						SPOTKAJMY SIĘ I POROZMAWIAJMY.
					</h1>
					<p className='text-center'>
						Zapraszamy na bezpłatne spotkanie, które potrwa maksymalnie 30 minut. W celu
						przygotowania dobrego spotkania, prosimy o odpowiedź na krótkie pytania, które
						zamieszczamy poniżej i wybór dogodnego dla Ciebie terminu. Skorzystaj z dostępu do
						kalendarza, który ukaże się po wysłaniu odpowiedzi.
					</p>
					<form onSubmit={handleSubmit} className='contents'>
						<div className='flex flex-col gap-3 schedule-form-holder w-full'>
							<div className='flex flex-col mb-10 gap-3'>
								<TextField
									label='Imię i nazwisko'
									color='black'
									required
									value={meetingChoices.fullName}
									name='fullName'
									onChange={(e) => handleChange(e, 'input')}
								/>
								<TextField
									label='Email'
									color='black'
									required
									type='email'
									value={meetingChoices.email}
									name='email'
									onChange={(e) => handleChange(e, 'input')}
								/>
								<TextField
									label='Numer telefonu'
									color='black'
									required
									type='tel'
									value={meetingChoices.phoneNumber}
									name='phoneNumber'
									onChange={(e) => handleChange(e, 'input')}
								/>
								<TextField
									label='Nazwa firmy'
									color='black'
									required
									value={meetingChoices.company}
									name='company'
									onChange={(e) => handleChange(e, 'input')}
								/>
							</div>
							<span className='font-bold'>
								Czy jesteś zainteresowana/zainteresowany (zaznacz swoje odpowiedzi):
							</span>

							<ul className='schedule-meeting-list'>{renderedForm}</ul>
							<div className='w-full'>
								<FormControlLabel
									control={<Checkbox color='black' />}
									label={
										<span>
											Akceptuję &nbsp;
											<Link to='/polityka-prywatnosci' className='font-bold'>
												politykę prywatności
											</Link>
										</span>
									}
									required
								/>
							</div>
							<button
								type='submit'
								className='submit-button mx-auto my-10 px-2 py-1 uppercase border-b border-black tracking-[3px]'>
								Prześlij odpowiedzi i wybierz termin spotkania
							</button>
						</div>
					</form>
				</div>
				{formFilled && (
					<div
						className={`calendly-inline-widget`}
						data-url='https://calendly.com/tomaszdalach?hide_gdpr_banner=1'
						style={{
							minWidth: 300,
							minHeight: 1200,
						}}></div>
				)}
			</div>
		</ThemeProvider>
	);
}
