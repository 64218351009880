import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../../images/logo.png';
import { MastermindPlusProps } from '../../mastermind-texts';
import { BsChevronCompactDown } from 'react-icons/bs';
import { Collapse } from '@mui/material';

type Props = {
	mastermindPage: number;
	setMastermindPage: React.Dispatch<React.SetStateAction<number>>;
	currentChildrenPage: number;
	setCurrentChildrenPage: React.Dispatch<React.SetStateAction<number>>;
	insightsClicked: boolean;
};

export default function MastermindPlusFooter({
	mastermindPage,
	setMastermindPage,
	insightsClicked,
	currentChildrenPage,
	setCurrentChildrenPage,
}: Props) {
	const [currentPageText, setCurrentPageText] = useState<string>(MastermindPlusProps.masterMindPlusTitles[0].value);

	function handleCurrentPageClick() {
		document.querySelector('.footer--expander')?.classList.toggle('active');
		document.body.style.position = 'fixed';
	}

	function handleCloseFooter() {
		document.querySelector('.footer--expander')?.classList.remove('active');
	}

	return insightsClicked ? (
		<>
			<footer className="w-full text-black site--footer flex justify-center items-center mastermind-footer">
				<BsChevronCompactDown className="absolute left-6" size={30} onClick={handleCurrentPageClick} />
				<span className="uppercase font-semibold text-xs tracking-[2.5px] text-center px-5 max-w-[300px]" onClick={handleCurrentPageClick}>
					{currentPageText}
				</span>
			</footer>
			<div className="footer--expander flex flex-col mastermind-expander">
				<ul className="flex gap-20 dotted-list in-place items-center tracking-[3px] mt-10">
					{MastermindPlusProps.masterMindPlusTitles.map((item: MastermindPlusProps.MastermindSidebarItem, index: number) => {
						return item.children ? (
							<li className={`flex flex-col gap-3 children-holder relative`}>
								<div
									onClick={() => {
										setMastermindPage(index);
										setCurrentChildrenPage(index);
									}}>
									{item.value}
								</div>
								<Collapse in={currentChildrenPage >= 4 && currentChildrenPage <= 12}>
									<ul className="flex flex-col gap-2 pl-5 dotted-list children">
										{item.children.map((title: string, subItemIndex: number) => {
											return (
												<li
													key={subItemIndex}
													className={`${currentChildrenPage - index === subItemIndex ? 'active' : ''}`}
													onClick={() => {
														setCurrentChildrenPage(subItemIndex + index);
														setCurrentPageText(title);
														handleCloseFooter();
													}}>
													<div>{title}</div>
												</li>
											);
										})}
									</ul>
								</Collapse>
							</li>
						) : (
							<li
								className={`${index === mastermindPage ? 'active' : ''}`}
								onClick={() => {
									setMastermindPage(index);
									setCurrentPageText(item.value);
									setCurrentChildrenPage(0);
									handleCloseFooter();
								}}>
								<div>{item.value}</div>
							</li>
						);
					})}
					<li>
						<div>
							<Link to="/">Strona główna OCEAN CAPITAL</Link>
						</div>
					</li>
				</ul>
			</div>
		</>
	) : (
		<></>
	);
}
