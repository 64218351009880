import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import People from '../../images/mastermind_people.png';
import { MastermindPlusProps } from '../mastermind-texts';
import ReactMarkdown from 'react-markdown';
import LogoSvg from '../components/LogoSvg';
import MastermindIcon from '../../images/icons/MastermindIcon';
import { Collapse } from '@mui/material';
import PartnerzyProgramu from './MastermindPlusPages/PartnerzyProgramu';
import MarkdownRenderer from './MastermindPlusPages/MarkdownRenderer';
import Zaloga from './MastermindPlusPages/Zaloga';
import CykleSpotkan from './MastermindPlusPages/CykleSpotkan';

type Props = {};

export default function MastermindPlus({}: Props) {
	function handleDotClick(e: React.MouseEvent<HTMLDivElement>) {
		const id: number = parseInt(e.currentTarget.id);

		document.querySelectorAll<HTMLDivElement>('.mastermind-circles .circle').forEach((circle: HTMLDivElement) => {
			if (circle.id !== 'circle-' + id) {
				circle.classList.remove('active');
			}
		});

		document.getElementById('circle-' + id)?.classList.toggle('active');
	}

	const [insightsClicked, setInsightsClicked] = useState<boolean>(false);

	const [currentPage, setCurrentPage] = useState<number>(0); // zero based
	const [currentChildrenPage, setCurrentChildrenPage] = useState<number>(0); // also zero based, it holds the value for currently expanded list

	const [currentComponent, setCurrentComponent] = useState<React.JSX.Element>();

	const [isRenderingMarkdown, setIsRenderingMarkdown] = useState<boolean>(false);

	const [circleColor, setCircleColor] = useState<string>('');

	useEffect(() => {
		document.querySelector('.mastermind-text-holder')?.scrollTo({
			top: 0,
			left: 0,
		});

		setIsRenderingMarkdown(false);

		if (currentPage >= 4 && currentPage <= 12) {
			setCurrentComponent(<CykleSpotkan meetingNumber={currentChildrenPage - currentPage} mainColor={circleColor} />);
			setCircleColor(MastermindPlusProps.mastermindMeetingCycleColors.at(currentChildrenPage - currentPage) || 'pink');
		} else {
			setCircleColor(MastermindPlusProps.mastermindPlusColors.at(currentPage) || 'pink');

			switch (currentPage) {
				case 0: {
					setCurrentComponent(<PartnerzyProgramu />);
					break;
				}
				case 3: {
					setCurrentComponent(<Zaloga />);

					break;
				}

				default: {
					setCurrentComponent(<MarkdownRenderer text={MastermindPlusProps.mastermindPlusTexts[currentPage]} h2Classes="font-medium" />);
					setIsRenderingMarkdown(true);
					break;
				}
			}
		}
	}, [currentPage, currentChildrenPage, circleColor]);

	function handleInsightsClick() {
		if (!insightsClicked) {
			setTimeout(() => {
				document.querySelector('.mastermind-plus-background')?.classList.add('active');
			}, 400);
		} else {
			document.querySelector('.mastermind-plus-background')?.classList.remove('active');
		}
		setInsightsClicked((prev) => !prev);
	}

	useEffect(() => {
		document.querySelectorAll('.circle').forEach((circle) => {
			circle.classList.remove('active');
			setTimeout(() => {
				circle.classList.toggle('expanded', insightsClicked);
				circle.classList.toggle('shrinked', !insightsClicked);
				setTimeout(() => {
					circle.classList.remove('shrinked');
					setCurrentPage(0);
					setCurrentChildrenPage(0);
				}, 500);
			}, 20);
		});
		document.querySelectorAll('.circle-small').forEach((circle) => {
			circle.classList.toggle('hidden', insightsClicked);
		});
		document.querySelector('.mastermind-content-displayer')?.classList.toggle('active', insightsClicked);
	}, [insightsClicked]);

	const [isOpen, setIsOpen] = useState(false);

	function handlePageChange(ind: number) {
		setCurrentPage(ind);
	}

	function handleCloseModal() {
		setIsOpen(false);
		mastermindModalRef.current?.classList.remove('active');
		overlayRef.current?.classList.remove('active');
		const html = document.querySelector('html');
		if (html) html.style.overflowY = 'visible';
	}

	function toggleBar() {
		setIsOpen((prev) => !prev);
		mastermindModalRef.current?.classList.toggle('active');
		overlayRef.current?.classList.toggle('active');
		console.log(overlayRef.current?.classList);
	}

	const mastermindModalRef = useRef<HTMLDivElement>(null);
	const barTogglerRef = useRef<HTMLDivElement>(null);
	const overlayRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const html = document.querySelector('html');

		if (isOpen) {
			barTogglerRef.current?.classList.add('open');
			if (html) html.style.overflowY = 'hidden';
		} else {
			barTogglerRef.current?.classList.remove('open');
			if (html) html.style.overflowY = 'visible';
		}
	}, [isOpen]);

	return (
		<div className="mastermind-content">
			<div id="overlay" onClick={handleCloseModal} ref={overlayRef}></div>

			<div className="mastermind-circles">
				<div className={`mastermind-icon ${!insightsClicked ? 'block' : 'hidden'} `} onClick={handleInsightsClick}>
					<MastermindIcon />
				</div>
				<div className="relative">
					<div className="circle" id="circle-1">
						<div className="!w-[600px]">
							<p className="tracking-[2px] text-3xl leading-loose">
								Strategiczny MASTERMIND + <br /> Zdefiniuj siebie na nowo jako liderkę lub lidera
							</p>
							<span className="font-bold opacity-50">EDYCJA 2024/25</span>
						</div>
					</div>
					<div className="circle-small first" id="1" onClick={handleDotClick}></div>
				</div>
				<div className="relative">
					<div className="circle" id="circle-2"></div>
				</div>
				<div className="relative">
					<div className="circle" id="circle-3"></div>
				</div>
				<div className="relative">
					<div className="circle" id="circle-4"></div>
				</div>
			</div>

			<div className="mastermind-plus-background z-[100]">
				<div
					id="circle-small"
					style={{
						background: `conic-gradient(from 180deg at 50% 50%, rgba(217, 217, 217, 0) 0deg, ${circleColor}  360deg)`,
					}}></div>
				<div
					id="circle-big"
					style={{
						background: `conic-gradient(from 180deg at 50% 50%, ${circleColor}  0deg, rgba(217, 217, 217, 0) 360deg)`,
					}}></div>
			</div>

			<div className="modal flex py-20 items-start justify-center z-[200] overflow-y-auto" ref={mastermindModalRef}>
				<div className="absolute top-5 right-5 cursor-pointer close--modal p-1 rounded-full hover:bg-zinc-100" onClick={handleCloseModal}>
					<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
						<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
					</svg>
				</div>

				<ul className="flex gap-20 dotted-list in-place items-center text-[13px] tracking-[3px] ">
					{MastermindPlusProps.masterMindPlusTitles.map((item: MastermindPlusProps.MastermindSidebarItem, index: number) => {
						return item.children ? (
							<li className={`flex flex-col gap-3 children-holder relative`}>
								<div
									onClick={() => {
										handlePageChange(index);
										setCurrentChildrenPage(index);
									}}>
									{item.value}
								</div>
								<Collapse in={currentChildrenPage >= 4 && currentChildrenPage <= 12}>
									<ul className="flex flex-col gap-2 pl-5 dotted-list children">
										{item.children.map((title: string, subItemIndex: number) => {
											return (
												<li
													key={subItemIndex}
													className={`${currentChildrenPage - index === subItemIndex ? 'active' : ''}`}
													onClick={() => {
														setCurrentChildrenPage(subItemIndex + index);
														handleCloseModal();
													}}>
													<div>{title}</div>
												</li>
											);
										})}
									</ul>
								</Collapse>
							</li>
						) : (
							<li
								className={`${index === currentPage ? 'active' : ''}`}
								onClick={() => {
									handlePageChange(index);
									setCurrentChildrenPage(0);
									handleCloseModal();
								}}>
								<div>{item.value}</div>
							</li>
						);
					})}
					<li>
						<div>
							<Link to="/">Strona główna OCEAN CAPITAL</Link>
						</div>
					</li>
				</ul>
			</div>
			{insightsClicked && (
				<div className="barToggler flex justify-center items-center cursor-pointer z-[400]" onClick={toggleBar} ref={barTogglerRef}>
					<div className="barToggler--button"></div>
				</div>
			)}
			<div className="mastermind-content-displayer text-white flex justify-between w-[85%]">
				<div className={`mastermind-icon outered ${insightsClicked ? 'block' : 'hidden'}`} onClick={handleInsightsClick}>
					<MastermindIcon />
				</div>

				<div className="flex flex-col dotted-list-holder">
					<ul className="max-h-[60vh] overflow-y-auto dotted-list pb-8">
						{MastermindPlusProps.masterMindPlusTitles.map((item: MastermindPlusProps.MastermindSidebarItem, index: number) => {
							return item.children ? (
								<li className={`flex flex-col gap-3 children-holder relative`}>
									<div
										onClick={() => {
											handlePageChange(index);
											setCurrentChildrenPage(index);
											handleCloseModal();
										}}>
										{item.value}
									</div>
									<Collapse in={currentChildrenPage >= 4 && currentChildrenPage <= 12}>
										<ul className="flex flex-col gap-2 pl-5 dotted-list children">
											{item.children.map((title: string, subItemIndex: number) => {
												return (
													<li
														key={subItemIndex}
														className={`${currentChildrenPage - index === subItemIndex ? 'active' : ''}`}
														onClick={() => {
															setCurrentChildrenPage(subItemIndex + index);
														}}>
														<div>{title}</div>
													</li>
												);
											})}
										</ul>
									</Collapse>
								</li>
							) : (
								<li
									className={`${index === currentPage ? 'active' : ''}`}
									onClick={() => {
										handlePageChange(index);
										setCurrentChildrenPage(0);
										handleCloseModal();
									}}>
									<div>{item.value}</div>
								</li>
							);
						})}
						<li>
							<div>
								<Link to="/">Strona główna OCEAN CAPITAL</Link>
							</div>
						</li>
					</ul>
					<div className="ml-[50px] w-fit py-[25px] ocean-logo mt-[12%]">
						<Link to="/" className="cursor-pointer w-min block">
							<LogoSvg />
						</Link>
					</div>
				</div>
				{isRenderingMarkdown ? (
					<div className="flex flex-col gap-10 max-h-[90vh] overflow-y-auto leading-[34px] mastermind-text-holder">{currentComponent}</div>
				) : (
					<div className="flex flex-col gap-10 max-h-[90vh] overflow-y-auto leading-[34px] mastermind-component-holder pr-5">
						{currentComponent}
					</div>
				)}
			</div>
		</div>
	);
}
