import React, { useEffect, useState } from 'react';
import RoutingDesktop from './layouts/desktop_tablet/Routing';
import RoutingMobile from './layouts/mobile/Routing';
import PrivacyPolicyAgreement from './layouts/PrivacyPolicyAgreement';

const DesktopTheme = React.lazy(() => import('./DesktopTheme'));
const MobileTheme = React.lazy(() => import('./MobileTheme'));

function App() {
	const deviceType = () => {
		if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
			return 'mobile';
		} else if (/iPad/i.test(navigator.userAgent)) {
			return 'tablet';
		} else {
			return 'desktop';
		}
	};

	const [layout, setLayout] = useState<JSX.Element>();
	const [type, setType] = useState<string>();

	useEffect(() => {
		setType(deviceType());
	}, []);

	useEffect(() => {
		if (type === undefined || type === null) return;
		console.log(type);
		switch (type) {
			case 'mobile': {
				setLayout(
					<RoutingMobile>
						<PrivacyPolicyAgreement />
					</RoutingMobile>
				);
				break;
			}
			default: {
				setLayout(
					<RoutingDesktop>
						<PrivacyPolicyAgreement />
					</RoutingDesktop>
				);

				break;
			}
		}
	}, [type]);

	return (
		<>
			<React.Suspense>
				{type && type != 'mobile' && <DesktopTheme />}
				{type && type == 'mobile' && <MobileTheme />}
			</React.Suspense>
			{layout && layout}
		</>
	);
}

export default App;
