import React, { useEffect, useState } from 'react';
import ProfilePicture from '../../images/AS1.jpg';
import PiotrZajac from '../../images/references/profile-pics/piotrzajac.jpg';
import NataliaMiller from '../../images/references/profile-pics/nataliamiller.jpg';
import MajaBazan from '../../images/references/profile-pics/majabazan.jpg';
import RobertProczko from '../../images/references/profile-pics/robertproczko.jpg';
import JoannaOstrowska from '../../images/references/profile-pics/joannaostrowska.jpg';
import { Link } from 'react-router-dom';
import AOS from 'aos';

type Props = {};

export default function ASInfo({}: Props) {
	// calculate initial heights for all the lists and hide them
	useEffect(() => {
		document
			.querySelectorAll<HTMLUListElement>('.expand--list')
			.forEach((list: HTMLUListElement) => {
				const height: number = list.clientHeight;
				list.style.height = height.toString() + 'px';
				list.classList.add('noheight');
			});

		document.body.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});

		setTimeout(() => {
			AOS.init();
		}, 200);
	}, []);

	const handleInfoClick = (e: React.MouseEvent<HTMLDivElement>) => {
		const target: EventTarget & HTMLDivElement = e.currentTarget;
		const list: Element | null = target.nextElementSibling;

		document.querySelectorAll<HTMLUListElement>('.expand--list').forEach((ul: HTMLUListElement) => {
			if (ul !== list) {
				ul.classList.remove('expanded');
				// turning the arrow back down
				ul.previousElementSibling?.classList.remove('expanded');

				ul.classList.add('noheight');
			}
		});

		list?.previousElementSibling?.classList.toggle('expanded');
		list?.classList.toggle('noheight');
	};

	return (
		<>
			<Link to='/mentoring'>
				<div id='go--back'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						fill='none'
						viewBox='0 0 24 24'
						strokeWidth={2}
						stroke='currentColor'
						className='w-8 h-8'>
						<path strokeLinecap='round' strokeLinejoin='round' d='M15.75 19.5L8.25 12l7.5-7.5' />
					</svg>
				</div>
			</Link>
			<div className='site--content mentoring--site ml-[10%] w-[85%] mr-auto no--limits'>
				<div
					className='mentor--info--window'
					data-aos='fade-up'
					data-aos-delay='500'
					data-aos-duration='1000'>
					<div className='flex w-full mr-auto gap-10'>
						<div className='mentor--content'>
							<section className='flex justify-between'>
								<div>
									<h1 className='uppercase text-2xl tracking-[5px] mb-10'>
										Jak mogę cię wesprzeć?
									</h1>
									<ul className='flex flex-col gap-5 mentor--list'>
										<li>
											<div onClick={handleInfoClick} className='uppercase cursor-pointer'>
												Strategia komunikacji
											</div>
											<ul className='list-none list-inside expand--list '>
												<li>
													Formułowanie celów komunikacyjnych firmy w oparciu o strategię biznesową
												</li>
												<li>Audyt komunikacji zewnętrznej</li>
												<li>Mapa interesariuszy w strategii komunikacji</li>
												<li>Kluczowe obszary do komunikacji</li>
												<li>Kanały komunikacji</li>
												<li>Wdrażanie planów komunikacji</li>
												<li>Benchmarking</li>
												<li>Analiza konkurencji</li>
												<li>Rebranding</li>
											</ul>
										</li>
										<li>
											<div
												onClick={handleInfoClick}
												className='flex gap-3 items-center text-lg font-bold  uppercase cursor-pointer'>
												ludzie i przywództwo
											</div>
											<ul className='list-none list-inside expand--list '>
												<li>
													Komunikacja i reputacja liderów - właścicieli, zarządu, managementu,
													pracowników
												</li>
												<li>Marka osobista i zarządzanie karierą </li>
												<li>Ochrona reputacji</li>
												<li>Zarządzanie wizerunkiem</li>
												<li>Marka osobista lidera jako osobiste aktywo </li>
												<li>Komunikacja i relacje w zespole </li>
												<li>Diagnoza stylów komunikacyjnych w zespołach </li>
												<li>Audyt komunikacji wewnętrznej </li>
												<li>Komunikacja w zespole</li>
												<li>Employer branding </li>
												<li>Komunikacja hybrydowa </li>
												<li>Komunikowanie decyzji i nadawanie sensu </li>
												<li>Wywiady medialne i wystąpienia publiczne </li>
												<li>Retoryka i logika w komunikacji lidera </li>
												<li>Rangowanie sukcesów menedżera w organizacji </li>
											</ul>
										</li>
										<li>
											<div
												onClick={handleInfoClick}
												className='flex gap-3 items-center text-lg font-bold  uppercase cursor-pointer'>
												sytuacje kryzysowe
											</div>
											<ul className='list-none list-inside expand--list '>
												<li>Komunikacja liderów w kryzysie </li>
												<li>Zarządzanie komunikacją kryzysową </li>
												<li>Symulacje możliwych sytuacji kryzysowych </li>
												<li>Efekt „złej prasy” i bezpośredni wpływ mediów na organizację </li>
												<li>Scenariusze kryzysowe i procedury antykryzysowe w organizacji </li>
											</ul>
										</li>
									</ul>
								</div>
								<div className='flex flex-col gap-2 items-center mr-5'>
									<img src={ProfilePicture} className='profile--image' />
									<span className='uppercase text-xs mt-2 font-[900] tracking-[4px]'>
										aleksandra ślifirska
									</span>
								</div>
							</section>
							<section>
								<h1 className='uppercase text-2xl tracking-[5px] mt-20 mb-10'>
									DOŚWIADCZENIE, ROZWÓJ
								</h1>
								<p className='mt-5'>
									Działam w biznesie od blisko 20 lat. Specjalizuję się w komunikacji liderów i
									organizacji. Moją pasją jest rozwój ludzi w zarządzaniu osobistą reputacją.
									Pracuję z zespołami zarządzającymi polskich firm, międzynarodowych koncernów, firm
									rodzinnych, prywatnych przedsiębiorstw. Prowadzę procesy komunikacji liderów w
									procesach rozwoju, fuzji, restrukturyzacji, sprzedaży, sukcesji. Bliskie mi są
									branże: finansowa, medyczna, farmaceutyczna, przemysłowa, IT, e-commerce, retail,
									motoryzacyjna i FMCG. Pracowałam m.in. z zespołami Deloitte, BASF, Volkswagen,
									Tauron, Lotte Wedel, Związek Polskich Faktorów, Fundacja Liderek Biznesu, Portal
									Praca.pl, Muzeum Śląskie, POLDENT, PKO BP, Santander, VOLVO, MAN Bus.
								</p>
								<p className='mt-3'>
									Swoje kompetencje rozwijam jako trener i ekspert ICAN Institute. W programie
									Personal Leadership Academy prowadzę sesje poświęcone marce osobistej liderów.
								</p>
								<p className='mt-3'>
									W 2020 roku znalazłam się na liście 100 najbardziej wpływowych kobiet Śląska. We
									współpracy z wydawnictwem PWN wydałam książki, które zdobyły pozycje bestsellerów:
									„Medialne lwy dla rekinów biznesu. Sztuka świadomego wykorzystywania mediów." oraz
									„Rekiny biznesu w mediach. Sztuka tworzenia profilu publicznego”. Pierwsza pozycja
									została nagrodzona przez Polskie Towarzystwo Trenerów Biznesu, druga przez
									miesięcznik My Company w kategorii biznesowe success story. Obydwie są wglądem w
									relacje międzyludzkie w biznesie i komunikację przywódczą.
								</p>
								<p className='mt-3'>
									W 2014 działalność mojej firmy została nagrodzona przez Związek Firm Public
									Relations za najlepszą kampanię lobbingową dla branży energii odnawialnych.
								</p>
							</section>
							<section>
								<h1 className='uppercase text-2xl tracking-[5px] mt-20 mb-10'>Wykształcenie</h1>
								<ul className='flex flex-col gap-3 '>
									<li>Uniwersytet Śląski, Filologia polska, literaturoznawstwo </li>

									<li>
										Trener ICAN Institute, wykładowca Management, Personal Leadership Academy oraz
										First Time Manager
									</li>
									<li>Akademia Psychologii Przywództwa, ukończone w roku 2023 z wyróżnieniem</li>
								</ul>
							</section>
							<section>
								<h1 className='uppercase text-2xl tracking-[5px] mt-20 mb-10'>Mentoring </h1>
								<ul className='flex flex-col gap-3 '>
									<li>Dla liderów w sytuacjach szczególnych wyzwań i zmian</li>
								</ul>
							</section>
							<section>
								<h1 className='uppercase text-2xl tracking-[5px] mt-20 mb-10'>Referencje </h1>
								<div className='reference--holder'>
									<div className='reference'>
										<div className='flex items-center gap-3'>
											<img src={PiotrZajac} />
											<div className='flex flex-col'>
												<p>Piotr Zając</p>
												<p>CEO Untitled Kingdom</p>
											</div>
										</div>
										<p className='mt-5 font-medium'>
											<i>
												„W realizacji strategii biznesowej komunikacja lidera jest bardzo ważna.
												Kiedy poprosiłem po raz pierwszy Aleksandrę Ślifirską o pomoc w
												przygotowaniu komunikacji dotyczącej misji i wizji mojej firmy, nie
												spodziewałem się, że ta współpraca wpłynie na mnie w takim stopniu. Przez te
												ostatnie kilkanaście miesięcy nauczyłem się więcej o komunikacji lidera, niż
												z jakiejkolwiek książki, którą przeczytałem. Podczas naszych spotkań, zawsze
												zaskakuje mnie z jaką precyzją Aleksandra wskazuje problem i jak proste do
												zastosowania daje rady. Każde nasze spotkanie to dla mnie inwestycja w
												rozwój zarówno Untitled Kingdom jak i mój.”
											</i>
										</p>
									</div>
									<div className='reference'>
										<div className='flex items-center gap-3'>
											<img src={NataliaMiller} />
											<div className='flex flex-col'>
												<p>Natalia Miller</p>
												<p>Customs Operations Poland Manager w Volvo Group</p>
											</div>
										</div>
										<p className='mt-5 font-medium'>
											<i>
												„Miałam niewątpliwą przyjemność uczestniczyć w szkoleniu "Personal Branding"
												prowadzonym przez Panią Olę. Dlaczego przyjemność? Po pierwsze Pani Ola
												posiada ogromną wiedzę na temat budowania marki własnej i, co równie ważne,
												potrafi ją skutecznie przekazać. Po drugie atmosfera wprowadzona przez Panią
												Olę bardzo pozytywnie wpływała na pracę i zaangażowanie grupy. Po trzecie, i
												chyba najważniejsze, szkolenie stanowiło dla mnie „a real eye-opener” czyli
												objawienie - dzięki wprowadzeniu w życie tylko kilku z wielu przekazanych
												wskazówek udało mi się wprowadzić kilka usprawnień do swojej działalności.
												Szkolenie i samo spotkanie z Panią Olą spowodowało olbrzymi przypływ dobrej
												energii, która jest ze mną do dziś. Pani Olu - dziękuję!”
											</i>
										</p>
									</div>
									<div className='reference'>
										<div className='flex items-center gap-3'>
											<img src={MajaBazan} />
											<div className='flex flex-col'>
												<p>Maja Bazan-Trojańska</p>
												<p>Head of Learning & Development, Raisin DS, Berlin</p>
											</div>
										</div>
										<p className='mt-5 font-medium'>
											<i>
												„Miałam przyjemność współpracować z Aleksandrą w 2021 r. w trakcie
												indywidualnego "coachsulting" dotyczącego budowania marki osobistej. Sesje
												były dla mnie bardzo odkrywcze oraz inspirujące. W trakcie procesu
												Aleksandra była cały czas zaangażowana i elastyczna, a jej umiętności
												aktywnego słuchania zasługują na szczególne podkreślenie. Na tej podstawie
												udało nam się wypracować szyte na miarę rozwiązania, które do dzisiaj
												stosuję w mojej pracy. Szczególnie cenię sobie praktyczność metod pracy oraz
												osadzenie ich w realiach biznesowych, dzięki temu wspólnie stworzony plan
												działania procentuje do dzisiaj. Serdecznie polecam Aleksandrę jako
												doświadczonego eksperta-praktyka a także empatycznego coacha.” 
											</i>
										</p>
									</div>
									<div className='reference'>
										<div className='flex items-center gap-3'>
											<img src={RobertProczko} />
											<div className='flex flex-col'>
												<p>Robert Proczko</p>
												<p>Biegły Sądowy | Inżynier budownictwa Członek zwyczajny SIDIR</p>
											</div>
										</div>
										<p className='mt-5 font-medium'>
											<i>
												„Miałem ogromną przyjemność pracować z Olą. To profesjonalistka mająca
												ogromne doświadczenie i wiedzę zarówno teoretyczną jak i praktyczną. Z całą
												pewnością jest najlepszym ekspertem komunikacji jakiegokolwiek dotąd
												spotkałem. Otwarta, komunikatywna, dostrzegająca zalety jak i wady klienta.
												Potrafi szybko zdiagnozować sytuację i znaleźć rozwiązanie. Z pewnością lubi
												wyzwania i trudne sytuacje wymagające szczególnego zaangażowania i
												nieszablonowych rozwiązań.”
											</i>
										</p>
									</div>
									<div className='reference'>
										<div className='flex items-center gap-3'>
											<img src={JoannaOstrowska} />
											<div className='flex flex-col'>
												<p>Joanna Ostrowska</p>
												<p>Dyr. zarządzająca siecią Osti-hotele</p>
											</div>
										</div>
										<p className='mt-5 font-medium'>
											<i>
												„Z pełnym przekonaniem polecam Aleksandrę Ślifirską jako doświadczonego
												mentora w procesie budowania marki osobistej. Łączy ona skutecznie dwie
												perspektywy: perspektywę skutecznego budowania relacji lidera z zespołami
												oraz tworzenia profilu publicznego, który zdecydowanie ułatwia nawiązywanie
												kluczowych relacji biznesowych. Praca z Olą była i jest dla mnie narzędziem
												rozwoju osobistego, co przekłada się także na rozwój mojego biznesu.
												Szczególnie ważne dla mnie w tym procesie jest możliwość odbywania rozmów
												rozwojowych, które ukierunkowują moje procesy decyzyjne i umożliwiają lepsze
												porozumiewanie się z ludźmi osobiście, a także poprzez media.”
											</i>
										</p>
									</div>
								</div>
							</section>
							<section>
								<h1 className='uppercase text-2xl tracking-[5px] mt-20 mb-10'>Nagrody </h1>
								<div className='awards--holder'>
									<p>
										Polskie Towarzystwo Trenerów Biznesu, nagroda Książka dla Trenera, nagroda
										publiczności dla książki „Medialne lwy dla rekinów biznesu”.
									</p>
									<p>
										My Company, nagroda w kategorii „Success story” za książkę „Rekiny biznesu w
										mediach. Sztuka tworzenia profilu publicznego”.
									</p>
								</div>
							</section>
							<section>
								<h1 className='text-2xl tracking-[5px] mt-20 mb-10'>LinkedIn </h1>
								<a
									href='https://www.linkedin.com/in/aleksandra-%C5%9Blifirska/ '
									target='_blank'
									className='font-bold'>
									https://www.linkedin.com/in/aleksandra-ślifirska/
								</a>
							</section>
							<section>
								<h1 className='uppercase text-[22px] tracking-[5px] mt-20 mb-10'>współpraca</h1>
								<p>
									Jeśli uważasz, że mogę pomóc Tobie, Twojemu zespołowi i Twojej organizacji, napisz
									do mnie na stronie
									<a href='/kontakt' className='font-semibold ml-1'>
										KONTAKT
									</a>
									. <br />
									Porozmawiajmy o tym, w jakim miejscu znajdujesz się dzisiaj oraz czym dla Ciebie i
									Twojego zespołu może być współpraca ze mną i z naszym zespołem.
								</p>
							</section>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
