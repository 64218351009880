import React from 'react';

type Props = {};

export default function PrivacyPolicy({}: Props) {
	return (
		<div className='py-20 flex flex-col gap-10 privacy-policy-content px-5 site--content'>
			<h1 className='text-2xl uppercase'>polityka prywatności</h1>
			<div className='flex flex-col gap-5'>
				<h2>
					Administratorem danych osobowych jest Ocean Capital Management, ul. Złotego Smoka 20/7,
					02-202 Warszawa
				</h2>
				<p>
					Mail kontaktowy w sprawie danych osobowych to{' '}
					<a href='mailto:ocean@oceancapital.pl' className='font-bold'>
						ocean@oceancapital.pl
					</a>
				</p>
			</div>

			<h3>1. Zbieranie informacji</h3>
			<ol>
				<li>
					<h4>1.1. Dane Osobowe:</h4>
					<ul>
						<li>
							Podczas wypełniania formularza kontaktowego zbieramy takie informacje jak: imię,
							nazwisko, adres e-mail i numer telefonu.
						</li>
						<li>
							Podczas korzystania z naszej strony, gromadzimy informacje dotyczące Twojej
							aktywności, przeglądarki internetowej, daty i godziny dostępu.
						</li>
					</ul>
				</li>
				<li>
					<h4>1.2. Ciasteczka (Cookies):</h4>
					<ul>
						<li>
							Wykorzystujemy pliki cookie do gromadzenia informacji ułatwiania korzystania z naszej
							strony. Możesz zmienić ustawienia dotyczące plików cookie w swojej przeglądarce.
						</li>
					</ul>
				</li>
			</ol>
			<h3>2. Wykorzystywane informacje</h3>
			<ol>
				<li>
					<h4>2.1. Dane osobowe:</h4>
					<ul>
						<li>
							Wykorzystujemy Twoje dane osobowe do udzielenia informacji o usługach, a także w
							celach marketingowych (o ile wyrazisz na to zgodę).
						</li>
					</ul>
				</li>

				<li>
					<h4>2.2. Dane analityczne:</h4>
					<ul>
						<li>
							Analizujemy dane dotyczące aktywności użytkowników w celu doskonalenia naszej strony i
							dostarczenia lepszych usług.
						</li>
					</ul>
				</li>
			</ol>
			<h3>3. Udostępnianie Informacji</h3>
			<ol>
				<li>
					<h4>3.1. Dostawcy Usług:</h4>
					<ul>
						<li>
							Możemy udostępniać Twoje dane osobowe dostawcom usług współpracującym z nami w celu
							świadczenia usług na naszą rzecz.
						</li>
					</ul>
				</li>
				<li>
					<h4>3.2. Wymagania Prawne:</h4>
					<ul>
						<li>
							Ujawnimy informacje, jeśli będzie to wymagane przez prawo, w celu ochrony naszych praw
							lub w przypadku zagrożenia bezpieczeństwa.
						</li>
					</ul>
				</li>
			</ol>

			<h3>4. Bezpieczeństwo Informacji</h3>
			<ol>
				<li>
					<h4>4.1. Środki Bezpieczeństwa:</h4>
					<ul>
						<li>
							Podjęliśmy odpowiednie środki bezpieczeństwa, aby chronić Twoje dane przed utratą,
							nieautoryzowanym dostępem, ujawnieniem, zmianą i zniszczeniem.
						</li>
					</ul>
				</li>
			</ol>

			<h3>5. Twoje Prawa</h3>
			<ol>
				<li>
					<h4>5.1. Dostęp do Danych:</h4>
					<ul>
						<li>Masz prawo dostępu do swoich danych osobowych i ich poprawiania.</li>
					</ul>
				</li>

				<li>
					<h4>5.2. Rezygnacja z Subskrypcji:</h4>
					<ul>
						<li>
							Możesz zrezygnować z subskrypcji naszych powiadomień marketingowych w dowolnym
							momencie.
						</li>
					</ul>
				</li>
			</ol>

			<h3>6. Zmiany w Polityce Prywatności</h3>
			<ol>
				<li>
					<h4>6.1. Aktualizacje:</h4>
					<ul>
						<li>Regularnie aktualizujemy naszą Politykę Prywatności.</li>
						<li>Ostatnia aktualizacja została dokonana 11 stycznia 2024 r.</li>
					</ul>
				</li>
			</ol>

			<p className='mt-5'>
				Zapraszamy do regularnego sprawdzania naszej Polityki Prywatności w celu zapoznania się z
				ewentualnymi zmianami.
			</p>
		</div>
	);
}
