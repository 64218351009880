import React, { useEffect, useState, useRef } from 'react';
import Sea from '../../images/sea.jpg';
import AOS from 'aos';
import axios, { AxiosResponse } from 'axios';
import * as EmailValidator from 'email-validator';
import { ThemeProvider } from '@emotion/react';
import { blackTheme } from '../globalTypes';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Link } from 'react-router-dom';

type Props = {};

interface formDataClass {
	fullName: string;
	phoneNumber: string;
	email: string;
	subject: string;
	company: string;
	message: string;
}

type ValidationResult = {
	valid: boolean;
	formattedPhoneNumber: string;
};

export default function Contact({}: Props) {
	const [formData, setFormData] = useState<formDataClass>({
		fullName: '',
		phoneNumber: '',
		email: '',
		subject: '',
		company: '',
		message: '',
	});

	const [emailSentSuccess, setEmailSentSuccess] = useState<boolean>();
	const [emailSentSuccessText, setEmailSentSuccessText] = useState<string>();

	const [fullNameValid, setFullNameValid] = useState<boolean>();
	const [phoneNumberValid, setPhoneNumberValid] = useState<boolean>();
	const [companyValid, setCompanyValid] = useState<boolean>();
	const [emailValid, setEmailValid] = useState<boolean>();
	const [subjectValid, setSubjectValid] = useState<boolean>();
	const [messageValid, setMessageValid] = useState<boolean>();

	const fullNameInputRef = useRef<HTMLInputElement>(null);
	const phoneNumberInputRef = useRef<HTMLInputElement>(null);
	const companyInputRef = useRef<HTMLInputElement>(null);
	const emailInputRef = useRef<HTMLInputElement>(null);
	const subjectInputRef = useRef<HTMLInputElement>(null);
	const messageInputRef = useRef<HTMLTextAreaElement>(null);

	useEffect(() => {
		if (fullNameValid === undefined) return;

		if (fullNameValid) {
			document.getElementById('fullname-input')?.classList.remove('invalid');
		} else {
			document.getElementById('fullname-input')?.classList.add('invalid');
		}
	}, [fullNameValid]);

	useEffect(() => {
		if (phoneNumberValid === undefined) return;

		if (phoneNumberValid) {
			document.getElementById('tel-input')?.classList.remove('invalid');
		} else {
			document.getElementById('tel-input')?.classList.add('invalid');
		}
	}, [phoneNumberValid]);

	useEffect(() => {
		if (companyValid === undefined) return;

		if (companyValid) {
			document.getElementById('company-input')?.classList.remove('invalid');
		} else {
			document.getElementById('company-input')?.classList.add('invalid');
		}
	}, [companyValid]);

	useEffect(() => {
		if (emailValid === undefined) return;

		if (emailValid) {
			document.getElementById('email-input')?.classList.remove('invalid');
		} else {
			document.getElementById('email-input')?.classList.add('invalid');
		}
	}, [emailValid]);

	useEffect(() => {
		if (subjectValid === undefined) return;

		if (subjectValid) {
			document.getElementById('subject-input')?.classList.remove('invalid');
		} else {
			document.getElementById('subject-input')?.classList.add('invalid');
		}
	}, [subjectValid]);

	useEffect(() => {
		if (messageValid === undefined) return;

		if (messageValid) {
			document.getElementById('message-input')?.classList.remove('invalid');
		} else {
			document.getElementById('message-input')?.classList.add('invalid');
		}
	}, [messageValid]);

	useEffect(() => {
		setTimeout(() => {
			document.querySelector('.sea--holder')?.classList.add('expanded');
		}, 1000);

		setTimeout(() => {
			AOS.init();
		}, 200);
	}, []);

	function handleFormChange(e: any) {
		const target = e.target;
		const name: string | null = target.getAttribute('name');
		const value = target.value;
		if (!name) return;

		setFormData({
			...formData,
			[name]: value,
		});
	}

	async function validateInputs(): Promise<boolean> {
		setFullNameValid(!!formData.fullName.length);
		setCompanyValid(!!formData.company.length);
		setMessageValid(!!formData.message.length);
		const emailValid: boolean = EmailValidator.validate(formData.email);
		setEmailValid(emailValid);
		setSubjectValid(!!formData.subject.length);

		let phone: string = formData.phoneNumber;
		if (!phone.startsWith('+48') && !phone.startsWith('+')) {
			phone = '+48' + phone;
		}

		const result: AxiosResponse<ValidationResult> = await axios.post(
			'https://api.oceancapital.pl/validate_phone_number',
			{
				phoneNumber: phone,
			}
		);

		let phoneValid: boolean;
		if (result.data.valid) {
			setPhoneNumberValid(true);
			phoneValid = true;
		} else {
			setPhoneNumberValid(false);
			phoneValid = false;
		}

		if (
			!formData.fullName.length ||
			!formData.company.length ||
			!formData.message.length ||
			!emailValid ||
			!phoneValid
		)
			return false;

		return true;
	}

	async function handleSendClick(e: React.MouseEvent) {
		const valid: boolean = await validateInputs();
		if (!valid) {
			document.querySelector('.error--label')?.classList.add('active');
			return;
		}
		document.querySelector('.error--label')?.classList.remove('active');

		let phone: string = formData.phoneNumber;
		if (!phone.startsWith('+48') && !phone.startsWith('+')) {
			phone = '+48' + phone;
		}

		await axios
			.post('https://api.oceancapital.pl/send_mail', {
				message: formData.message,
				fromEmail: formData.email,
				mailSubject: formData.subject,
				phoneNumber: phone,
				fullName: formData.fullName,
				company: formData.company,
			})
			.then((result: AxiosResponse) => {
				if (result.status === 200) {
					setEmailSentSuccess(true);
					console.log('OK');
				} else {
					setEmailSentSuccess(false);
					console.log('WRONG');
				}
			})
			.catch((err) => {
				console.log(err);
				console.log('error while sending');
				setEmailSentSuccess(false);
			});
	}

	let timerEnded: NodeJS.Timeout;

	useEffect(() => {
		const sentLabel: HTMLLabelElement | null = document.querySelector('.sent--text');
		if (emailSentSuccess === undefined || !sentLabel) return;

		if (emailSentSuccess) {
			sentLabel.style.color = 'black';
			setEmailSentSuccessText('Wysłano');
			if (
				!fullNameInputRef.current ||
				!emailInputRef.current ||
				!companyInputRef.current ||
				!phoneNumberInputRef.current ||
				!subjectInputRef.current ||
				!messageInputRef.current
			)
				return;

			fullNameInputRef.current.value = '';
			emailInputRef.current.value = '';
			companyInputRef.current.value = '';
			phoneNumberInputRef.current.value = '';
			subjectInputRef.current.value = '';
			messageInputRef.current.value = '';
			setFormData({
				fullName: '',
				phoneNumber: '',
				email: '',
				subject: '',
				company: '',
				message: '',
			});
		} else {
			sentLabel.style.color = '#C41E3A';
			setEmailSentSuccessText('Wystąpił błąd');
		}
		sentLabel.classList.add('active');

		clearTimeout(timerEnded);

		timerEnded = setTimeout(() => {
			sentLabel.classList.remove('active');
			setTimeout(() => {
				setEmailSentSuccess(undefined);
			}, 500);
		}, 3000);
	}, [emailSentSuccess]);

	function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
	}

	return (
		<>
			<ThemeProvider theme={blackTheme}>
				<div className='sea--holder'>
					<img src={Sea} />
				</div>
				<div className='site--content'>
					<div
						className='contact--holder flex flex-col gap-10 items-center'
						data-aos='fade-left'
						data-aos-delay='1500'
						data-aos-duration='1000'>
						<ul className='flex flex-col contact--list self-start'>
							<li>e: ocean@oceancapital.pl </li>
						</ul>
						<div className='flex flex-col w-full gap-4 mb-12'>
							<div className='flex items-center gap-3 h-[32px] relative'>
								<label className='error--label'>Popraw podświetlone pola</label>
								<h1 className='uppercase text-2xl form--title tracking-[3px]'>zadaj pytanie</h1>
								<span className='sent--text'>{emailSentSuccessText}</span>
							</div>

							<form className='flex flex-col form--holder' onSubmit={handleSubmit}>
								<div id='fullname-input'>
									<input
										type='text'
										placeholder='Imię i nazwisko'
										name='fullName'
										onChange={handleFormChange}
										ref={fullNameInputRef}
									/>
								</div>
								<div id='email-input'>
									<input
										type='email'
										placeholder='E-mail'
										name='email'
										onChange={handleFormChange}
										ref={emailInputRef}
									/>
								</div>
								<div id='company-input'>
									<input
										type='text'
										placeholder='Organizacja / Firma'
										name='company'
										onChange={handleFormChange}
										ref={companyInputRef}
									/>
								</div>
								<div id='tel-input'>
									<input
										type='tel'
										placeholder='Numer telefonu +48-...'
										name='phoneNumber'
										onChange={handleFormChange}
										ref={phoneNumberInputRef}
									/>
								</div>
								<div id='subject-input'>
									<input
										type='text'
										placeholder='Temat'
										name='subject'
										onChange={handleFormChange}
										ref={subjectInputRef}
									/>
								</div>
								<div className='relative pb-5'>
									<div id='message-input'>
										<textarea
											placeholder='Treść wiadomości'
											name='message'
											onChange={handleFormChange}
											className='w-full'
											ref={messageInputRef}
										/>
									</div>
									<FormControlLabel
										className='w-full px-[15px] pb-[20px]'
										control={<Checkbox color='black' size='small' />}
										label={
											<span className='text-sm'>
												Akceptuję &nbsp;
												<Link to='/polityka-prywatnosci' className='font-bold'>
													politykę prywatności
												</Link>
											</span>
										}
										required
									/>
									<button className='send--button' onClick={handleSendClick}>
										wyślij
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</ThemeProvider>
		</>
	);
}
