import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ProfilePicture from '../../images/MG1.jpg';
import AOS from 'aos';

type Props = {};

export default function TD({}: Props) {
	// calculate initial heights for all the lists and hide them
	useEffect(() => {
		document
			.querySelectorAll<HTMLUListElement>('.expand--list')
			.forEach((list: HTMLUListElement) => {
				const height: number = list.clientHeight;
				list.style.height = height.toString() + 'px';
				list.classList.add('noheight');
			});

		document.querySelector('.site--content')?.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});

		setTimeout(() => {
			AOS.init();
		}, 200);
	}, []);

	const handleInfoClick = (e: React.MouseEvent<HTMLDivElement>) => {
		const target: EventTarget & HTMLDivElement = e.currentTarget;
		const list: Element | null = target.nextElementSibling;

		document.querySelectorAll<HTMLUListElement>('.expand--list').forEach((ul: HTMLUListElement) => {
			if (ul !== list) {
				ul.classList.remove('expanded');
				// turning the arrow back down
				ul.previousElementSibling?.classList.remove('expanded');

				ul.classList.add('noheight');
			}
		});

		list?.previousElementSibling?.classList.toggle('expanded');
		list?.classList.toggle('noheight');
	};

	return (
		<>
			<Link to='/mentoring'>
				<div id='go--back'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						fill='none'
						viewBox='0 0 24 24'
						strokeWidth={2}
						stroke='currentColor'
						className='w-8 h-8'>
						<path strokeLinecap='round' strokeLinejoin='round' d='M15.75 19.5L8.25 12l7.5-7.5' />
					</svg>
				</div>
			</Link>
			<div className='site--content'>
				<div
					className='mentor--info--window'
					data-aos='fade-up'
					data-aos-delay='500'
					data-aos-duration='1000'>
					<div className='flex flex-col gap-2 items-center w-full mb-10'>
						<img src={ProfilePicture} className='profile--image' />
						<span className='uppercase text-xs mt-2 font-[900] tracking-[4px]'>
							Mieczysław Grudziński
						</span>
					</div>
					<div className='mentor--content'>
						<section className='flex justify-between'>
							<div>
								<h1 className='uppercase text-2xl tracking-[5px] mb-10'>Jak mogę cię wesprzeć?</h1>
								<ul className='flex flex-col gap-5 mentor--list'>
									<li>
										<div onClick={handleInfoClick} className='uppercase cursor-pointer'>
											Strategia firmy
										</div>
										<ul className='list-none list-inside expand--list '>
											<li>Budowa strategii finansowej firmy</li>
										</ul>
									</li>
									<li>
										<div
											onClick={handleInfoClick}
											className='flex gap-3 items-center text-lg font-bold  uppercase cursor-pointer'>
											Sprawy korporacyjne firmy
										</div>
										<ul className='list-none list-inside expand--list '>
											<li>Pomoc w zakresie nadzoru właścicielskiego w spółce kapitałowej</li>
											<li>Doradztwo dla zarządu w obszarze finansów spółki kapitałowej</li>
										</ul>
									</li>
									<li>
										<div
											onClick={handleInfoClick}
											className='flex gap-3 items-center text-lg font-bold  uppercase cursor-pointer'>
											Przekształcenia własnościowe
										</div>
										<ul className='list-none list-inside expand--list '>
											<li>Budowanie wartości i przygotowanie firmy do sprzedaży inwestorom</li>
											<li>
												Pomoc w przygotowaniu pierwszej publicznej oferty akcji (IPO) na rynku
												pierwotnym i wtórnym
											</li>
											<li>Pomoc w transakcjach M&A</li>
											<li>Dywestycje, spin-offs i inne formy restrukturyzacji finansowej firmy</li>
										</ul>
									</li>
									<li>
										<div
											onClick={handleInfoClick}
											className='flex gap-3 items-center text-lg font-bold  uppercase cursor-pointer'>
											Sytuacje kryzysowe w finansach
										</div>
										<ul className='list-none list-inside expand--list '>
											<li>Diagnoza zagrożenia płynności firmy w krótkiej perspektywie</li>
											<li>
												Pomoc w rozwiązywaniu problemów wypłacalności firmy w długim horyzoncie
											</li>
											<li>Restrukturyzacja kapitałowa firmy</li>
										</ul>
									</li>
									<li>
										<div
											onClick={handleInfoClick}
											className='flex gap-3 items-center text-lg font-bold  uppercase cursor-pointer'>
											Finansowanie firmy i jej inwestycji
										</div>
										<ul className='list-none list-inside expand--list '>
											<li>Analityka i budżetowanie przedsięwzięć inwestycyjnych</li>
											<li>Finansowanie inwestycji</li>
											<li>Przygotowanie emisji akcji i obligacji</li>
										</ul>
									</li>
								</ul>
							</div>
						</section>
						<section>
							<h1 className='uppercase text-2xl tracking-[5px] mt-20 mb-10'>doświadczenie</h1>
							<p className='mt-5'>
								Jestem profesorem Akademii Leona Koźmińskiego, specjalizuję się w finansach
								korporacyjnych i rynkach kapitałowych. Byłem zatrudniony jako Adjunct Professor w
								University of Illinois w Urbana-Champaign, gdzie przez 20 lat prowadziłem wykłady na
								studiach Executive MBA prowadzonych razem z Uniwersytetem Warszawskim. Przez wiele
								lat wykładałem finanse w programie Executive MBA University of Maryland i
								Uniwersytetu Łódzkiego. Opublikowałem kilka podręczników, kilkaset artykułów.
								Wykładam finanse w programach AMP i EMBA w wielu uczelniach, m. in. UW, UŁ i ALK.
								Prowadzę szkolenia dla firm i instytucji, m. in. dla Parlamentu Europejskiego i
								Komisji Europejskiej w Luksemburgu. Łączę naukę i dydaktykę z praktyką - jestem
								autorem pierwszych w Polsce publicznych ofert akcji na GPW w Warszawie, negocjowałem
								kilkanaście akwizycji kapitałowych, wspieram rozwój firm doradzając im w biznesie.
								Pełniłem funkcje nadzorcze w wielu spółkach publicznych i prywatnych, jestem
								partnerem w OCEAN CAPITAL i niezależnym ekspertem w dziedzinie finansów. W wolnym
								czasie słucham muzyki, jeżdżę na nartach.
							</p>
						</section>
						<section>
							<h1 className='uppercase text-2xl tracking-[5px] mt-20 mb-10'>Wykształcenie</h1>
							<ul className='flex flex-col gap-3 '>
								<li>Absolwent Politechniki Śląskiej</li>
								<li>Doktorat z ekonomii - Uniwersytet Łódzki </li>
								<li>
									Staże na University of Illinois w Urbana-Champaign i University of Maryland w
									College Park
								</li>
							</ul>
						</section>
						<section>
							<h1 className='uppercase text-2xl tracking-[5px] mt-20 mb-10'>Wybrane publikacje</h1>
							<ul className='flex flex-col gap-3 '>
								<li>
									Grudziński M. (2013), Corporate Finance: A Focused Approach, Cengage Learning
									Custom Edition
								</li>
								<li>
									Grudziński M., Obłój K. (2015, 2007), Rozdział 7. Strategia: teoria opcji
									realnych, w: Obłój K. (red.), Strategia organizacji, PWE
								</li>
								<li>
									Grudziński M. (2014), Zarządzanie finansami firmy międzynarodowej, w: Obłój K.,
									Wąsowska A. (red.), Zarządzanie międzynarodowe: Teoria i praktyka, PWE
								</li>
							</ul>
						</section>
						<section>
							<h1 className='uppercase text-2xl tracking-[5px] mt-20 mb-10'>
								Nagrody i wyróżnienia
							</h1>
							<ul className='flex flex-col gap-3 '>
								<li>
									Pośród wielu innych otrzymałem wyróżnienia dla najlepszego wykładowcy siedmiu
									edycji programu Executive MBA Uniwersytetu Warszawskiego i University of Illinois
									w Urbana-Champaign (2002, 2003, 2004, 2005, 2011, 2018, 2019)
								</li>
							</ul>
						</section>
						<section>
							<h1 className='text-2xl tracking-[5px] mt-20 mb-10'>LinkedIn </h1>
							<a
								href='https://www.linkedin.com/in/mieczys%C5%82aw-grudzi%C5%84ski-420bb813/ '
								target='_blank'
								className='font-bold'>
								https://www.linkedin.com/in/mieczysław-grudziński/
							</a>
						</section>
						<section>
							<h1 className='uppercase text-[22px] tracking-[5px] mt-20 mb-10'>współpraca</h1>
							<p>
								Jeśli uważasz, że mogę pomóc Tobie, Twojemu zespołowi i Twojej organizacji, napisz
								do mnie na stronie
								<a href='/kontakt' className='font-semibold ml-1'>
									KONTAKT
								</a>
								. <br />
								Porozmawiajmy o tym, w jakim miejscu znajdujesz się dzisiaj oraz czym dla Ciebie i
								Twojego zespołu może być współpraca ze mną i z naszym zespołem.
							</p>
						</section>
					</div>
				</div>
			</div>
		</>
	);
}
