import React from 'react';
import { useLocation } from 'react-router-dom';
import PageFooter from './PageFooter';

type Props = {};

export default function Footer({}: Props) {
	const location = useLocation();

	let properFooter;

	switch (location.pathname) {
		case '/mastermind':
		case '/mastermind-plus': {
			properFooter = <></>;
			break;
		}

		default: {
			properFooter = <PageFooter />;
			break;
		}
	}

	return properFooter;
}
