import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../images/logo.png';

type Props = {};

export default function Header({}: Props) {
	function handleLogoClick() {
		document.querySelector('.footer--expander')?.classList.add('active');
	}
	const location = useLocation();

	const noHeaderRoutes: string[] = ['/mastermind', '/mastermind-plus'];

	return (
		<header>
			<div
				className={`site--header flex justify-between py-8 pl-5 items-center pr-[5%] ${
					noHeaderRoutes.includes(location.pathname) ? 'hidden' : ''
				}`}>
				<img src={Logo} className="max-h-[50px] w-auto" onClick={handleLogoClick} />
			</div>
		</header>
	);
}
