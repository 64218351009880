import React, { useEffect } from 'react';
import Szkolenia from '../../images/icons/szkolenia.svg';
import Webinary from '../../images/icons/webinary.svg';
import Rekrutacja from '../../images/icons/pomoc_w_rekrutacji.svg';
import Shadowing from '../../images/icons/shadowing.svg';
import Inne from '../../images/icons/inne_produkty.svg';
import RN from '../../images/icons/rada_nadzorcza.svg';
import Mastermind from '../../images/icons/leadership_mastermind.svg';
import Konsultacje from '../../images/icons/konsultacje.svg';
import MentoringGrupowy from '../../images/icons/mentoring_grupowy.svg';
import Mentoring11 from '../../images/icons/mentoring_1do1.svg';

type Props = {};

export default function Measure({}: Props) {
	useEffect(() => {}, []);
	return (
		<div className='site--content mx-auto w-[90%]'>
			<div className='flex flex-col w-full items-center'>
				<h1 className='uppercase text-[22px] tracking-[5px] w-min mt-28'>
					NASZA <br />
					OFERTA <br />
					MENTORINGU, <br /> KONSULTACJI, <br />
					SZKOLEŃ, <br />I WSPARCIA <br /> PROJEKTOWEGO
				</h1>
				<div className='measure--grid'>
					<div className='flex flex-col items-center '>
						<img src={Mentoring11} />
					</div>
					<div className='flex flex-col items-center '>
						<img src={MentoringGrupowy} />
					</div>
					<div className='flex flex-col items-center '>
						<img src={Mastermind} />
					</div>
					<div className='flex flex-col items-center '>
						<img src={Konsultacje} />
					</div>
					<div className='flex flex-col items-center '>
						<img src={Szkolenia} />
					</div>
					<div className='flex flex-col items-center '>
						<img src={Webinary} />
					</div>
					<div className='flex flex-col items-center '>
						<img src={Rekrutacja} />
					</div>
					<div className='flex flex-col items-center '>
						<img src={Shadowing} />
					</div>
					<div className='flex flex-col items-center '>
						<img src={RN} />
					</div>
					<div className='flex flex-col items-center '>
						<img src={Inne} />
					</div>
				</div>
			</div>
			<div className='my-20 text-center'>
				Jeśli uważasz, że możemy pomóc Tobie, Twojemu zespołowi i Twojej organizacji, napisz do nas
				na stronie
				<a href='/kontakt' className='font-semibold ml-1'>
					KONTAKT
				</a>
				. Porozmawiajmy o tym, w jakim miejscu znajdujesz się dzisiaj oraz czym dla Ciebie i Twojego
				zespołu może być współpraca z naszym zespołem.
			</div>
		</div>
	);
}
