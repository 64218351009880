import React, { useEffect } from 'react';

type Props = {};

export default function Intro({}: Props) {
	useEffect(() => {
		document
			.querySelector<HTMLDivElement>('.site--content')
			?.style.setProperty('--actualViewportHeight', `${window.innerHeight}px`);

		if (!sessionStorage.getItem('firstLoad')) {
			sessionStorage.setItem('firstLoad', 'true');
			document.querySelector('.footer--expander')?.classList.add('active');
		}
	}, []);
	return (
		<div className='site--content w-[80%] mx-auto pt-10'>
			<section className='flex gap-20'>
				<div className='flex flex-col gap-6 mt-7 intro--text'>
					<h1 className='intro--title mb-2'>Liderko, Liderze,</h1>
					<p>
						Wierzymy, że siłą każdej organizacji są <strong>ludzie i wartości,</strong> które ich
						łączą.
					</p>

					<p>
						<strong>Stałą cechą</strong> otaczającego nas świata jest <strong>zmiana</strong>.
					</p>

					<p>
						<strong>Wszechstronne przywództwo lidera</strong> niesie odpowiedzialność za ludzi oraz
						za wszystkie obszary funkcjonowania firmy.
					</p>

					<p>
						<strong>Holistyczne podejście</strong> do biznesu buduje sukces firmy.
					</p>

					<p>
						<strong> Dbałość o fundamenty</strong> takie jak strategia, wyniki finansowe, procesy,
						sprzedaż, marketing, R&D i komunikacja jest również kluczowym ogniwem rozwoju ludzi i
						firm.
					</p>

					<p>
						<strong>RELACJE</strong>. Są ważne i cenimy je. Rozwijają ludzi w życiu zawodowym i
						osobistym. A fundamentem ich budowania jest &nbsp;<strong>ROZWÓJ LIDERÓW</strong>.
					</p>

					<p>
						Wierzymy, że <strong>dobre życie i dobrostan</strong> są tym, na czym zależy każdemu z
						nas.
					</p>
				</div>
			</section>
			<section>
				<h1 className='intro--title mb-10 mt-[150px]'>Co nas &#322;&#261;czy? </h1>
				<div className='flex flex-col'>
					<ul className='intro--list'>
						<li>
							Z Mieczysławem pracujemy od 20 lat. Z Olą blisko 10. <br />
						</li>
						<li>
							<strong>
								Realizujemy wspólną pasję: wspieramy liderki i liderów w ich rozwoju oraz w rozwoju
								ich zespołów i biznesów.
							</strong>
						</li>
						<li>
							Preferujemy pracę w modelu mentoringowym, w którym Ty, Twój zespół oraz Wasze cele są
							w centrum uwagi, a Ty i Twój zespół wdrażacie najlepsze rozwiązania. Jeśli Twoim
							wyborem jest tradycyjny konsulting, jesteśmy gotowi wesprzeć Cię również w taki
							sposób.
						</li>
						<li>
							Prowadzimy spotkania osobiście. Wiele z nich odbywa się on-line z wykorzystaniem
							popularnych technologii komunikacji.
						</li>
						<li>
							Dzielimy się wieloletnim doświadczeniem realizacji setek projektów, budowaniem
							własnych firm, zarządzaniem nimi, realizacją tysięcy godzin mentoringowych,
							konsultingowych, spotkań, wykładów, warsztatów, webinariów. Stale rozwijamy nasze
							kompetencje, dzieląc się nimi we współpracy z liderami.
						</li>
						<li>
							Jeśli uważasz, że możemy pomóc Tobie, Twojemu zespołowi i Twojej organizacji, napisz
							do nas na stronie
							<a href='/kontakt' className='font-semibold ml-1'>
								KONTAKT
							</a>
							. Porozmawiajmy o tym, w jakim miejscu znajdujesz się dzisiaj oraz czym dla Ciebie i
							Twojego zespołu może być współpraca z naszym zespołem.
						</li>
					</ul>
					<div className='flex flex-col my-40 items-center tomasz--info'>
						<strong className='uppercase'>tomasz dalach</strong>
						<p>Mentor Liderek i Liderów</p>
						<p>Założyciel OCEAN CAPITAL</p>
						<p>e: ocean@oceancapital.pl</p>
					</div>
				</div>
			</section>
		</div>
	);
}
