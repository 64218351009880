import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

type Props = {};

export default function NotFound({}: Props) {
	useEffect(() => {}, []);
	return (
		<div className='flex flex-col w-[80%] gap-32 mx-auto mt-16'>
			<h1 className='text-5xl font-light tracking-[5px] uppercase'>Error 404 - Not Found</h1>
			<p className='return--home'>
				<Link to='/'>Return home</Link>
			</p>
		</div>
	);
}
