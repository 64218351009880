import React from 'react';
import { Link } from 'react-router-dom';
import Grupowe2 from '../../images/grupowe2.jpg';
import { CgChevronRight } from 'react-icons/cg';

type Props = {};

export default function ContactPage({}: Props) {
	return (
		<div className='my-32 mx-auto w-[80%] flex flex-col'>
			<div className='flex items-end justify-between contact-intro'>
				<div className='flex flex-col gap-5 w-1/2'>
					<i className='text-3xl text-opacity-50 text-black'>
						„Spotkania wytyczają drogę ludzkiego losu, niezależnie od tego, czy trwają kilka godzin,
						kilka dni, czy całe życie.”
					</i>
					<span className='spaced-text'>THERESA RÉVAY</span>
				</div>
				<img src={Grupowe2} className='w-2/5' />
			</div>
			<div className='mt-32'>
				<h1 className='text-[22px] tracking-[3px] contact-title'>SPOTKAJMY SIĘ I POROZMAWIAJMY.</h1>
				<div className='contact-grid'>
					<Link to='umow-spotkanie'>
						<div>
							<span>
								Wybierz dla siebie dogodny termin i umów się z nami na spotkanie informacyjne,
								dotyczące współpracy. Możemy spotkać się osobiście lub online poprzez aplikację
								zoom.
							</span>
							<div className='ml-auto px-2 py-1 flex items-center'>
								Przejdź
								<CgChevronRight size={25} />
							</div>
						</div>
					</Link>
					<Link to='formularz'>
						<div>
							<span>Jeśli wolisz formę pisemną, napisz do nas przez formularz kontaktowy</span>
							<div className='ml-auto px-2 py-1 flex items-center'>
								Przejdź <CgChevronRight size={25} />
							</div>
						</div>
					</Link>
				</div>
			</div>
		</div>
	);
}
