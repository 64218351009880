import React, { useEffect } from 'react';
import TD from '../../images/TD2.jpg';
import MG from '../../images/MG2.jpg';
import AS from '../../images/AS2.jpg';
import MentoringInset from './MentoringInset';
import { Link, useOutlet } from 'react-router-dom';

type Props = {};

export default function Mentoring({}: Props) {
	useEffect(() => {
		document.body.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, []);

	const outlet = useOutlet();

	return (
		outlet || (
			<>
				<div className='site--content'>
					<section className='mentoring--image--grid mt-8'>
						<div className='cursor-pointer mentor--block'>
							<Link to='tomasz-dalach'>
								<img src={TD} />
								<span className='mentor--name'>Tomasz Dalach</span>
							</Link>
							<MentoringInset />
						</div>
						<div className='cursor-pointer mentor--block'>
							<Link to='aleksandra-slifirska'>
								<img src={AS} />
								<span className='mentor--name'>Aleksandra Ślifirska</span>
							</Link>
							<MentoringInset />
						</div>
						<div className='cursor-pointer mentor--block'>
							<Link to='mieczyslaw-grudzinski'>
								<img src={MG} />
								<span className='mentor--name'>Mieczysław Grudziński </span>
							</Link>
							<MentoringInset />
						</div>
					</section>
				</div>
			</>
		)
	);
}
