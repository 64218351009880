import React, { useEffect, useState } from 'react';
import ProfilePicture from '../../images/TD1.jpg';
import JustynaKrzyzanowskaRef from '../../images/references/justynakrzyzanowska.png';
import JustynaKrzyzanowska from '../../images/references/profile-pics/justynakrzyzanowska.jpg';
import UrszulaWysocka from '../../images/references/profile-pics/urszulawysocka.jpg';
import KarolBielecki from '../../images/references/profile-pics/karolbielecki.jpg';
import AleksandraDezakowska from '../../images/references/profile-pics/aleksandradezakowska.jpg';
import AleksandraDezakowskaRef1 from '../../images/references/aleksandradezakowska-1.png';
import AleksandraDezakowskaRef2 from '../../images/references/aleksandradezakowska-2.png';
import MalgorzataNowak from '../../images/references/profile-pics/MałgorzataNowakNiedzwiedzka.jpeg';
import MateuszKubiczek from '../../images/references/profile-pics/MateuszKubiczek.jpeg';
import AleksandraZazdrozna from '../../images/references/profile-pics/AleksandraZadrozna.jpeg';
import LukaszBulatek from '../../images/references/profile-pics/LukaszBulatek.jpeg';
import MalgorzataNowakRef from '../../images/references/malgorzatanowak.jpg';
import MateuszKubiczekRef from '../../images/references/mateuszkubiczek.jpg';
import AleksandraZazdroznaRef from '../../images/references/aleksandrazazdrozna.jpg';
import LukaszBulatekRef1 from '../../images/references/lukaszbulatek-1.jpg';
import LukaszBulatekRef2 from '../../images/references/lukaszbulatek-2.jpg';
import BarbaraNiedzwiedzka from '../../images/references/profile-pics/barbaraniedzwiedzka.jpg';
import BarbaraNiedzwiedzkaRef from '../../images/references/barbaraniedzwiedzka.png';
import AnnaDyakowska from '../../images/references/profile-pics/annadyakowska.jpeg';
import AnnaDyakowskaRef from '../../images/references/annadyakowska.jpg';
import { Link } from 'react-router-dom';
import AOS from 'aos';

type Props = {};

export default function TD({}: Props) {
	const [imageToDisplay, setImageToDisplay] = useState<React.ReactElement>();

	// calculate initial heights for all the lists and hide them
	useEffect(() => {
		document
			.querySelectorAll<HTMLUListElement>('.expand--list')
			.forEach((list: HTMLUListElement) => {
				const height: number = list.clientHeight;
				list.style.height = height.toString() + 'px';
				list.classList.add('noheight');
			});

		document.body.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});

		setTimeout(() => {
			AOS.init();
		}, 200);
	}, []);

	function handleCloseImageDisplay() {
		const overlay = document.getElementById('overlay');
		overlay?.classList.remove('active');
		document.getElementById('image--displayer')?.classList.remove('active');
		setImageToDisplay(undefined);
	}

	const handleInfoClick = (e: React.MouseEvent<HTMLDivElement>) => {
		const target: EventTarget & HTMLDivElement = e.currentTarget;
		const list: Element | null = target.nextElementSibling;

		document.querySelectorAll<HTMLUListElement>('.expand--list').forEach((ul: HTMLUListElement) => {
			if (ul !== list) {
				ul.classList.remove('expanded');
				// turning the arrow back down
				ul.previousElementSibling?.classList.remove('expanded');

				ul.classList.add('noheight');
			}
		});

		list?.previousElementSibling?.classList.toggle('expanded');
		list?.classList.toggle('noheight');
	};

	function handleReferenceClick(e: React.MouseEvent) {
		const target: EventTarget & Element = e.currentTarget;
		const attr: string | null = target.getAttribute('data-reference');
		if (!attr) return;

		const index: number = parseInt(attr);

		switch (index) {
			case 1: {
				setImageToDisplay(<img src={MalgorzataNowakRef} />);
				break;
			}
			case 2: {
				setImageToDisplay(<img src={MateuszKubiczekRef} />);
				break;
			}
			case 3: {
				setImageToDisplay(<img src={JustynaKrzyzanowskaRef} />);
				break;
			}

			case 6: {
				setImageToDisplay(<img src={AleksandraZazdroznaRef} />);
				break;
			}

			case 7: {
				setImageToDisplay(
					<>
						<img src={AleksandraDezakowskaRef1} />
						<img src={AleksandraDezakowskaRef2} />
					</>
				);
				break;
			}

			case 8: {
				setImageToDisplay(
					<>
						<img src={LukaszBulatekRef1} />
						<img src={LukaszBulatekRef2} />
					</>
				);
				break;
			}

			case 9: {
				setImageToDisplay(<img src={BarbaraNiedzwiedzkaRef} />);
				break;
			}
		}
		document.getElementById('image--displayer')?.classList.add('active');
		document.getElementById('overlay')?.classList.add('active');
	}

	return (
		<>
			<div id='image--displayer' onClick={handleCloseImageDisplay}>
				{imageToDisplay}
			</div>
			<div id='overlay' onClick={handleCloseImageDisplay}></div>
			<Link to='/mentoring'>
				<div id='go--back'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						fill='none'
						viewBox='0 0 24 24'
						strokeWidth={2}
						stroke='currentColor'
						className='w-8 h-8'>
						<path strokeLinecap='round' strokeLinejoin='round' d='M15.75 19.5L8.25 12l7.5-7.5' />
					</svg>
				</div>
			</Link>
			<div className='site--content mentoring--site ml-[10%] w-[85%] mr-auto no--limits'>
				<div
					className='mentor--info--window'
					data-aos='fade-up'
					data-aos-delay='500'
					data-aos-duration='1000'>
					<div className='mentor--content'>
						<section className='flex justify-between'>
							<div>
								<h1 className='uppercase text-[22px] tracking-[5px] mb-10'>
									Jak mogę cię wesprzeć?
								</h1>
								<ul className='flex flex-col gap-5 mentor--list'>
									<li>
										<div
											onClick={handleInfoClick}
											className='flex gap-3 items-center text-[14px] font-bold  uppercase cursor-pointer'>
											LUDZIE, LIDERZY, PRZYWÓDZTWO
										</div>
										<ul className='list-none list-inside expand--list '>
											<li>
												Rozwój liderów: <br />
												właścicieli, prezesów i członków zarządu, managementu, dyrektorów, <br />
												kierowników, pracowników, „talents &amp; high potentials”
											</li>
											<li>Wzmacnianie pozycji mened&#380;er&#243;w, pracownik&#243;w </li>
											<li>Rola odpowiedzialno&#347;ci w pracy i &#380;yciu osobistym</li>
											<li>Delegowanie odpowiedzialno&#347;ci, decyzyjno&#347;ci, zada&#324;</li>
											<li>Budowanie motywacji</li>
											<li>Budowanie autorytetu</li>
											<li>Jak wyjść z „Nie mam na to czasu”?</li>
											<li>Zarządzanie czasem własnym</li>
											<li>„Sparring partner” dla prezesa zarządu, zarządu, liderów</li>
											<li>Negocjacje</li>
											<li>Warto&#347;ci firmy</li>
											<li>
												Kultura organizacyjna firmy - identyfikacja, kszta&#322;towanie, rozw&#243;j
											</li>
											<li>Komunikacja w firmie i na zewn&#261;trz firmy </li>
											<li>
												U&#347;pienie sukcesami i ich skutki w przysz&#322;o&#347;ci. Jak
												zapobiega&#263;?
											</li>
											<li>Struktura organizacyjna </li>
											<li>Dob&#243;r w&#322;a&#347;ciwych os&#243;b do powierzanych zada&#324; </li>
											<li>Employer branding </li>
											<li>
												Po co w firmie dyrektor finansowy, dyrektor sprzeda&#380;y, dyrektor
												marketingu, dyrektor HR, dyrektor technologii, dyrektor...?
											</li>
											<li>Procesy onboardingowe</li>
											<li>Integracja i wsp&#243;&#322;praca w pracy zdalnej </li>
										</ul>
									</li>
									<li>
										<div onClick={handleInfoClick} className='uppercase cursor-pointer'>
											Zespoły i komunikacja
										</div>
										<ul className='list-none list-inside expand--list '>
											<li>Struktura i jej rozwój wraz ze wzrostem biznesu oraz organizacji</li>
											<li>Budowanie zespołów</li>
											<li>Zarządzanie zespołami</li>
											<li>Współpraca w zespole oraz między zespołami</li>
											<li>Co to znaczy i jak rozpoznać, że ludzie rozumieją mnie, jako lidera?</li>
											<li>Dlaczego poszczególne osoby lub zespół mnie nie rozumie?</li>
											<li>
												Ty rozumiesz mnie, ja rozumiem Ciebie – porozumienie między ludźmi i w
												zespole
											</li>
											<li>Zarządzanie zmianą i komunikacja zmiany</li>
											<li>Komunikacja w zespołach</li>
											<li>Komunikacja między zespołami</li>
											<li>Komunikacja do interesariuszy</li>
											<li>Komunikacja w organizacji i poza nią</li>
											<li>Komunikacja planów i ważnych wydarzeń</li>
											<li>Komunikacja pojawienia się nowych pracowników oraz odchodzenia</li>
											aktualnych
											<li>Komunikacja zdarzeń krytycznych</li>
											<li>Komunikacja kryzysowa</li>
											<li>Komunikacja w innych sytuacjach</li>
										</ul>
									</li>
									<li>
										<div onClick={handleInfoClick} className='uppercase cursor-pointer'>
											Strategia firmy
										</div>
										<ul className='list-none list-inside expand--list '>
											<li>Formu&#322;owanie strategii firmy</li>
											<li>Egzekucja strategii firmy</li>
											<li>Modele biznesowe</li>
											<li>Jak zwi&#281;ksza&#263; przychody</li>
											<li>Skalowanie biznesu</li>
											<li>Dostarczanie klientom warto&#347;ci</li>
											<li>Customer Centricity</li>
											<li>Misja firmy</li>
											<li>Wizja firmy</li>
											<li>Kluczowe kompetencje firmy</li>
											<li>Kluczowe wyzwania firmy</li>
											<li>Benchmarking</li>
											<li>Analiza konkurencji</li>
											<li>Uruchamianie nowych przedsi&#281;wzi&#281;&#263;</li>
											<li>Budowa biznes&#243;w zagranicznych</li>
										</ul>
									</li>

									<li>
										<div
											onClick={handleInfoClick}
											className='flex gap-3 items-center text-[14px] font-bold  uppercase cursor-pointer'>
											Sprzeda&#380; i marketing
										</div>
										<ul className='list-none list-inside expand--list '>
											<li>
												Rozw&#243;j oraz optymalizacja proces&#243;w sprzeda&#380;y i marketingu
											</li>
											<li>Jak podnosi&#263; ceny za us&#322;ugi i produkty </li>
											<li>Kiedy obniżać ceny i jak to to robić?</li>
											<li>Po co nam cele, KPI i "excele"?</li>
											<li>Plany sprzedaży i ich realizacja</li>
											<li>Analityka w sprzeda&#380;y i marketingu </li>
											<li>Sprzedaż czy marketing - co ważniejsze?</li>
											<li>Customer Service</li>
											<li>Customer Experience</li>
											<li>Utrzymanie i rozwój klienta</li>
											<li>Działania marketingowe, sprzedażowe</li>
											<li>E-commerce</li>
											<li>Leady, konwersja</li>
											<li>Obsługa klienta, relacji z klientami</li>
											<li>Klient indywidualny vs. klient „masowy”</li>
										</ul>
									</li>
									<li>
										<div
											onClick={handleInfoClick}
											className='flex gap-3 items-center text-[14px] font-bold  uppercase cursor-pointer'>
											sytuacje kryzysowe
										</div>
										<ul className='list-none list-inside expand--list '>
											<li>
												Jak zapobiega&#263; sytuacjom kryzysowym, jak przez nie przechodzi&#263; i
												jak budowa&#263; na nich lepsz&#261; przysz&#322;o&#347;&#263;?
											</li>
											<li>Kryzys w rozwoju biznesu </li>
											<li>
												Kryzys wywo&#322;any czynnikami zewn&#281;trznymi, na kt&#243;re nie mamy
												wp&#322;ywu, np. wojna, pandemia, regulacje prawne, licencje, zmiany
												rynkowe, konkurencja
											</li>
											<li>Kryzys w relacjach mi&#281;dzy wsp&#243;lnikami </li>
											<li>Kryzys w relacjach mi&#281;dzy zarz&#261;dem a pracownikami </li>
											<li>Kryzys we wsp&#243;&#322;pracy mi&#281;dzy pracownikami w zespole </li>
											<li>Kryzys w komunikacji mi&#281;dzy zespo&#322;ami </li>
											<li>"My czy oni" - kt&#243;ry dzia&#322; w firmie jest najwa&#380;nieszy?</li>
										</ul>
									</li>
									<li>
										<div
											onClick={handleInfoClick}
											className='flex gap-3 items-center text-[14px] font-bold  uppercase cursor-pointer'>
											Sprawy korporacyjne
										</div>
										<ul className='list-none list-inside expand--list '>
											<li>Po co firmie Rada Nadzorcza? </li>
											<li>Rola zarz&#261;du, Rady Nadzorczej i w&#322;a&#347;cicieli </li>
											<li>
												Zasady funkcjonowania, obowi&#261;zki i prawa zarz&#261;du, rady nadzorczej
												i w&#322;a&#347;cicieli
											</li>
										</ul>
									</li>
									<li>
										<div
											onClick={handleInfoClick}
											className='flex gap-3 items-center text-[14px] font-bold  uppercase cursor-pointer'>
											Finanse, zarz&#261;dzanie warto&#347;ci&#261;, IPO, CFO, relacje inwestorskie
										</div>
										<ul className='list-none list-inside expand--list '>
											<li>Zarządzanie finansami</li>
											<li>Co zrobić z zarobionymi pieniędzmi?</li>
											<li>
												Po co w firmie CFO, dyrektor sprzedaży, dyrektor marketingu, dyrektor HR,
												dyrektor techniczny i inne kluczowe role?
											</li>
											<li>Budżetowanie</li>
											<li>Analityka i raportowanie w firmie</li>
											<li>Rola KPI</li>
											<li>Budowa wartości dla akcjonariuszy i inwestorów</li>
											<li>Przygotowanie biznesów do wprowadzenia inwestorów</li>
											<li>Pozyskiwania finansowania Venture Capital i Private Equity</li>
											<li>Przygotowanie IPO</li>
											<li>Współpraca z inwestorami finansowymi i branżowymi</li>
											<li>Relacje inwestorskie</li>
											<li>Przygotowanie firmy do sprzedaży</li>
											<li>Proces sprzedaży firmy</li>
											<li>Wyjście z inwestycji</li>
										</ul>
									</li>
									<li>
										<div
											onClick={handleInfoClick}
											className='flex gap-3 items-center text-[14px] font-bold  uppercase cursor-pointer'>
											Technologie w firmie
										</div>
										<ul className='list-none list-inside expand--list '>
											<li>Rola technologii w biznesie </li>
											<li>Technologie w zarz&#261;dzaniu firm&#261; </li>
											<li>Zespo&#322;y rozproszone</li>
											<li>Praca zdalna </li>
											<li>E-commerce </li>
										</ul>
									</li>
									<li>
										<div
											onClick={handleInfoClick}
											className='flex gap-3 items-center text-[14px] font-bold  uppercase cursor-pointer'>
											Formy pracy
										</div>
										<ul className='list-none list-inside expand--list '>
											<li>
												Mentoring właścicieli, członków zarządu, członków kluczowego managementu,
												talentów przygotowywanych do tych ról
											</li>
											<li>Praca warsztatowa i szkoleniowa z grupami</li>
											<li>Interaktywne wykłady dla zespołów</li>
											<li>Sprawdzone programy oraz tematyka</li>
											<li>Spotkania on-line</li>
											<li>
												Programy przygotowywane pod potrzeby liderów, dla danego zespołu i danej
												organizacji
											</li>
										</ul>
									</li>
								</ul>
							</div>
							<div className='flex flex-col gap-2 items-center mr-5'>
								<img src={ProfilePicture} className='profile--image' />
								<span className='uppercase text-xs mt-2 font-[900] tracking-[4px]'>
									tomasz dalach
								</span>
							</div>
						</section>
						<section>
							<h1 className='uppercase text-[22px] tracking-[5px] mt-20 mb-10'>
								DOŚWIADCZENIE, ROZWÓJ
							</h1>
							<p className='mt-5'>
								Jestem przedsiębiorcą od blisko 30 lat. Wiem i czuję co to znaczy być
								odpowiedzialnym za ludzi, biznes, organizację, za wyniki, sprawy formalne. Za
								wszystko to, co przewidywalne oraz za to, co nieznane i czasami zaskakujące. Jako
								właściciel, udziałowiec i zarządzający współtworzyłem wiele firm, w tym jedną z
								największych platform e-commerce w Polsce, zatrudniającą ponad 300 osób. Posiadam
								doświadczenie pracy z poziomu właścicielskiego, organów nadzoru oraz liderskiego -
								zarządu lub mentora i doradcy. Z czasem pasja budowania biznesów przerodziła się w
								pasję wspierania rozwoju liderów i ich organizacji. <br />W pracy z ludźmi preferuję
								model mentoringowy, według mnie dający liderom najlepszą możliwość budowania
								rozwiązań dla swoich zespołów i dla nich samych. Przepracowałem dotąd tysiące godzin
								jako mentor, doradca zarządów i właścicieli oraz jako tzw. interim manager.
								Doświadczenie budowałem w pracy ze spółkami z branży IT, software, e-commerce,
								firmami usługowymi, handlowymi, produkcyjnymi - zarówno polskimi, działałającymi
								międzynarodowo, jak i międzynarodowymi. <br />
								Od kilku lat rozwijam się w obszarze psychologii przywództwa. Unikatowość mojej
								pracy mentoringowej opiera się na czterech filarach: psychologii liderów, pracy
								przedsiębiorcy, pasji rozwoju biznesów oraz stosowania narzędzi strategicznych m.in.
								Executive MBA. Od ponad roku realizuję jeden z najbardziej wyjątkowych „projektów”:
								współpracę z Olafem, moim synem. Olaf jest programistą, tworzy prace w ramach &nbsp;
								<a href='https://ph4sm4solutions.com' target='_blank' className='phasma--link'>
									phasmasolutions.com
								</a>
								, a zaprogramował i rozwija m.in. tę stronę internetową.
							</p>
						</section>
						<section>
							<h1 className='uppercase text-[22px] tracking-[5px] mt-20 mb-10'>Wykształcenie</h1>
							<ul className='flex flex-col gap-3 '>
								<li>
									Wydzia&#322; Elektroniki i Technik Informacyjnych Politechniki Warszawskiej 2000
								</li>
								<li>
									Warsaw-Illinois Executive MBA, University of Illinois, Uniwersytet Warszawski,
									uko&#324;czone z wyr&#243;&#380;nieniem 2005
								</li>
								<li>Kurs dla kandydat&#243;w na cz&#322;onk&#243;w rad nadzorczych 2007</li>
								<li>
									Kurs dla doradc&#243;w inwestycyjnych i analityk&#243;w papier&#243;w
									warto&#347;ciowych 2009
								</li>
								<li>
									Akademia Psychologii Przyw&#243;dztwa, uko&#324;czone z wyr&#243;&#380;nieniem
									2020
								</li>
								<li>Akademia Psychologii Przyw&#243;dztwa - Autorytety 2022</li>
							</ul>
						</section>
						<section>
							<h1 className='uppercase text-[22px] tracking-[5px] mt-20 mb-10'>Mentoring </h1>
							<ul className='flex flex-col gap-3 '>
								<li>OCEAN CAPITAL</li>
								<li>Akademia Liderek Biznesu</li>
								<li>Executive MBA@UW - Mentor projekt&#243;w doradczych</li>
							</ul>
						</section>
						<section>
							<h1 className='uppercase text-[22px] tracking-[5px] mt-20 mb-10'>Referencje </h1>

							<div className='reference--holder'>
								<div className='reference'>
									<div className='flex items-center gap-3'>
										<img src={MalgorzataNowak} />
										<div className='flex flex-col'>
											<p>Małgorzata Nowak-Niedźwiedzka</p>
											<p>Wiceprezes Zarządu</p>
											<p>POLDENT</p>
										</div>
									</div>
									<p className='mt-5 font-medium'>
										<i>
											„Czasy się zmieniają, firma rośnie, wiedza „plemienna” już nie wystarcza,
											istnieje potrzeba bardziej systemowego podejścia do wielu zagadnień. M.in. z
											powyższych powodów, ponad 2 lata temu, rozpoczęliśmy współpracę z panem
											Tomaszem Dalachem. Przez cały czas korzystamy z jego dużej wiedzy biznesowej i
											wieloletniego doświadczenia jako mentora. To pozwala naszej firmie przejść na
											wyższy poziom funkcjonowania, lepsze planowanie pracy, bardziej efektywne
											pokonywanie barier. Razem tworzymy strategie, pozwalające na szybszy rozwój
											firmy.”
										</i>
									</p>
									<p
										className='flex w-full justify-start mt-5 cursor-pointer whole--reference'
										onClick={handleReferenceClick}
										data-reference={1}>
										Pełna treść referencji:
									</p>
								</div>
								<div className='reference'>
									<div className='flex items-center gap-3'>
										<img src={MateuszKubiczek} />
										<div className='flex flex-col'>
											<p>Mateusz Kubiczek</p>
											<p>Prezes Zarządu</p>
											<p>THE SOFTWARE HOUSE</p>
										</div>
									</div>
									<p className='mt-5 font-medium'>
										<i>
											„Łącząc to wszystko razem, działamy w sposób bardziej przemyślany i
											zaplanowany, a duch zespołu, który obudził się podczas tych spotkań, pomógł
											nam przejść przez trudny czas pandemii z najlepszym w historii wynikiem
											finansowym. Obecny rok, 2022, zakończymy mając 300 osób i wielu nowych
											klientów na pokładzie, wiedząc, że to właśnie Tomek pomógł nam sobie ten cel
											postawić i zmotywował nas, by do niego dążyć.”
										</i>
									</p>
									<p
										className='flex w-full justify-start mt-5 cursor-pointer whole--reference'
										onClick={handleReferenceClick}
										data-reference={2}>
										Pełna treść referencji:
									</p>
								</div>
								<div className='reference'>
									<div className='flex items-center gap-3'>
										<img src={JustynaKrzyzanowska} />
										<div className='flex flex-col'>
											<p>Justyna Krzyżanowska</p>
											<p>Category Manager</p>
											<p>BLACK RED WHITE</p>
										</div>
									</div>
									<p className='mt-5 font-medium'>
										<i>
											„Wiedza, którą wyciągnęłam z mentoringu była tak obszerna i szeroka, iż
											potrzebowałam czasu, aby ją w pełni przyswoić i wdrożyć w życie. Nasze
											spotkania na długo zostaną w mojej pamięci, gdyż były moją drogą ku
											samoświadomości i odkryciu lepszej wersji siebie. Pozwoliły mi pozbyć się
											swoich ograniczeń i skupić na wewnętrznej sile, którą każdy z Nas w sobie ma
											tylko nie zawsze potrafi ją dostrzec. Polecam współpracę z Tomkiem z pełnym
											przekonaniem, że jest to najlepsza inwestycja w siebie!”.
										</i>
									</p>
									<p
										className='flex w-full justify-start mt-5 cursor-pointer whole--reference'
										onClick={handleReferenceClick}
										data-reference={3}>
										Pełna treść referencji:
									</p>
								</div>
								<div className='reference'>
									<div className='flex items-center gap-3'>
										<img src={BarbaraNiedzwiedzka} />
										<div className='flex flex-col'>
											<p>Barbara Niedźwiedzka-Pająk</p>
											<p>Członek Zarządu, Dyrektor Marketingu</p>
											<p>POLDENT</p>
										</div>
									</div>
									<p className='mt-5 font-medium'>
										<i>
											„Od samego początku wiedza i doświadczenie Tomka pozwoliły nam spojrzeć na
											organizację firmy z innej perspektywy. Spotkania nie były łatwe ze względu
											mierzenie się przez nas ze status quo. Tomek sprawiał, że dyskusje jednak
											zawsze były merytoryczne, a poruszane przez niego tematy inspirujące (…).
											Rekomenduję Tomasza Dalacha jako Mentora właścicieli, prezesów, menedżerów,
											liderów i rozwoju całych organizacji!”
										</i>
									</p>
									<p
										className='flex w-full justify-start mt-5 cursor-pointer whole--reference'
										onClick={handleReferenceClick}
										data-reference={9}>
										Pełna treść referencji:
									</p>
								</div>
								<div className='reference'>
									<div className='flex items-center gap-3'>
										<img src={AnnaDyakowska} />
										<div className='flex flex-col'>
											<p>Anna Dyakowska</p>
											<p>PROJEKTANTKA GRAFICZNA</p>
										</div>
									</div>
									<p className='mt-5 font-medium'>
										<img className='dyakowskaimg' src={AnnaDyakowskaRef} />
									</p>
								</div>
								<div className='reference'>
									<div className='flex items-center gap-3'>
										<img src={UrszulaWysocka} />
										<div className='flex flex-col'>
											<p>Urszula Wysocka</p>
											<p>BANKING AND FINANCE EXPERT</p>
										</div>
									</div>
									<p className='mt-5 font-medium'>
										<i>
											„Z Tomkiem znamy się od 10 lat. Jesteśmy świadkami naszych dróg rozwoju. Tomek
											jest osobą, która inspiruje oraz wskazuje nowe kierunki działania, myślenia.
											Chętnie dzieli się swoim doświadczeniem. To On pokazał mi, że będąc mentorem
											warto też być mentee. Dzięki rozmowom z Tomkiem przekonałam się, że nasz
											rozwój nigdy się nie kończy, a nasze doświadczenia często mobilizują nas do
											wyjścia ze swojej strefy komfortu, co może być drogą do sukcesu. Rekomenduję
											Tomka jako Mentora Liderów, którzy chcą stawić czoła każdemu wyzwaniu.”
										</i>
									</p>
								</div>
								<div className='reference'>
									<div className='flex items-center gap-3'>
										<img src={KarolBielecki} />
										<div className='flex flex-col'>
											<p>Karol Bielecki</p>
											<p>Prezes Zarządu</p>
											<p>ACGROUP</p>
										</div>
									</div>
									<p className='mt-5 font-medium'>
										<i>
											„Wiedza to kapitał. Ocean Capital inspiruje i poszerza horyzonty, co daje
											impuls do działania oraz wdrażania nowych idei we własnym środowisku
											biznesowym.”
										</i>
									</p>
								</div>
								<div className='reference'>
									<div className='flex items-center gap-3'>
										<img src={AleksandraZazdrozna} />
										<div className='flex flex-col'>
											<p>Aleksandra Zadrożna</p>
											<p>Kierownik Działu Szkoleń</p>
											<p>POLDENT</p>
										</div>
									</div>
									<p className='mt-5 font-medium'>
										<i>
											„Naszą pracę mogę porównać do stopni, które pokonuję każdego dnia. Kiedy
											trafiam na stopień, który “kosztuje” mnie więcej emocji, pracy i pojawiają się
											chwile zawahania wiem, że Tomek nie pozwoli mi spaść i będę mogła liczyć na
											jego wsparcie. Z czasem poczułam, że pokonywanie kolejnych stopni staje się
											dla mnie coraz łatwiejsze, a coraz więcej z nich pokonuję samodzielnie.
											Postęp, który obserwuję przynosi mi satysfakcję i motywację do działania. Chcę
											budować na tym dalszy swój rozwój oraz rozwój ludzi, za których jestem
											odpowiedzialna”.
										</i>
									</p>
									<p
										className='flex w-full justify-start mt-5 cursor-pointer whole--reference'
										onClick={handleReferenceClick}
										data-reference={6}>
										Pełna treść referencji:
									</p>
								</div>
								<div className='reference'>
									<div className='flex items-center gap-3'>
										<img src={AleksandraDezakowska} />
										<div className='flex flex-col'>
											<p>Aleksandra Deżakowska</p>
											<p>Lekarz dentysta, przedsiębiorca i startuper</p>
											<p>HD-DENTAL CENTRUM STOMATOLOGII, DENT'S PLAY</p>
										</div>
									</div>
									<p className='mt-5 font-medium'>
										<i>
											„Proces rozwoju, w którym uczestniczyłam z Tomkiem, pozwolił mi na poukładanie
											procesów i założeń nowej firmy, uniknięcie wielu błędów, a przede wszystkim
											dał mi możliwość korzystania z obszernej wiedzy i doświadczeń Tomka w każdej z
											dziedzin, nad którą pracowaliśmy. Gdyby nie ta współpraca, wszystko trwałoby o
											wiele dłużej, byłoby dla mnie o wiele większym obciążeniem emocjonalnym.
											Największą wartością tych kilku miesięcy było dla mnie możliwość podzielenia
											się swoimi wątpliwościami i obawami z osobą o wiele większym przygotowaniu
											biznesowym niż ja. A wszystko to - w atmosferze pełnej zaufania i akceptacji.”
										</i>
									</p>
									<p
										className='flex w-full justify-start mt-5 cursor-pointer whole--reference'
										onClick={handleReferenceClick}
										data-reference={7}>
										Pełna treść referencji:
									</p>
								</div>
								<div className='reference'>
									<div className='flex items-center gap-3'>
										<img src={LukaszBulatek} />
										<div className='flex flex-col'>
											<p>Łukasz Bułatek</p>
											<p>Customer Relations Manager</p>
											<p>POLDENT</p>
										</div>
									</div>
									<p className='mt-5 font-medium'>
										<i>
											„Bardzo ważną zmianą w moim życiu i karierze było pojawienie się w firmie
											Tomka Dalacha, który od kilku miesięcy jest moim mentorem, a współpraca trwa
											nadal. Ten czas zmienił moją osobę, moje spojrzenie na otaczający mnie świat i
											ludzi, moje życie i moją pracę. Tomka mentoring pozwolił mi wybrać drogę,
											którą chcę podążać, aby osiągnąć sukces osobisty i aby zmieniać to, co wymaga
											zmiany na lepsze. Doświadczyłem dzięki Tomkowi swojej wielkiej przemiany
											osobistej.”
										</i>
									</p>
									<p
										className='flex w-full justify-start mt-5 cursor-pointer whole--reference'
										onClick={handleReferenceClick}
										data-reference={8}>
										Pełna treść referencji:
									</p>
								</div>
							</div>
						</section>
						<section>
							<h1 className='uppercase text-[22px] tracking-[5px] mt-20 mb-10'>
								NAGRODY I WYRÓŻNIENIA
							</h1>
							<ul className='flex flex-col gap-3 '>
								<li>
									Najlepsza praca z marketingu - Executive MBA University of Illinois at
									Urbana-Champaing i Uniwersytet Warszawski, 2005.
								</li>
								<li>
									Executive MBA University of Illinois at Urbana-Champaing i Uniwersytet Warszawski
									- ukończone z wyróżnieniem, 2005.
								</li>
								<li>50 najbardziej kreatywnych ludzi w biznesie - Magazyn Brief, 2013.</li>
								<li>Nagroda „Doświadczenie” - Fundacja Liderek Biznesu, 2020.</li>
								<li>
									Akademia Psychologii Przywództwa, Szkoła Biznesu Politechniki Warszawskiej -
									ukończone z wyróżnieniem, 2020.
								</li>
							</ul>
						</section>
						<section>
							<h1 className='text-[22px] tracking-[5px] mt-20 mb-10'>LinkedIn </h1>
							<a
								href='https://linkedin.com/in/tomasz-dalach/'
								target='_blank'
								className='font-bold'>
								www.linkedin.com/in/tomasz-dalach/
							</a>
						</section>
						<section>
							<h1 className='uppercase text-[22px] tracking-[5px] mt-20 mb-10'>współpraca</h1>
							<p>
								Jeśli uważasz, że mogę pomóc Tobie, Twojemu zespołowi i Twojej organizacji, napisz
								do mnie na stronie
								<a href='/kontakt' className='font-semibold ml-1'>
									KONTAKT
								</a>
								. <br />
								Porozmawiajmy o tym, w jakim miejscu znajdujesz się dzisiaj oraz czym dla Ciebie i
								Twojego zespołu może być współpraca ze mną i z naszym zespołem.
							</p>
						</section>
					</div>
				</div>
			</div>
		</>
	);
}
