import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

type Props = {};

export default function PrivacyPolicyAgreement({}: Props) {
	const [cookies, setCookies] = useCookies(['privacy-policy-accepted']);

	const router = useLocation();

	useEffect(() => {
		if (!cookies['privacy-policy-accepted']) {
			document.getElementById('overlay')?.classList.add('active');
			setTimeout(() => {
				document.getElementById('privacy-policy')?.classList.add('active');
			}, 300);
		}
	}, [router.pathname]);

	function handleAccept() {
		document.getElementById('overlay')?.classList.remove('active');
		document.getElementById('privacy-policy')?.classList.remove('active');
		setTimeout(() => {
			setCookies('privacy-policy-accepted', true, { path: '/' });
		}, 800);
	}

	// on the page with full privacy policy we do not want to display the agreement
	return !cookies['privacy-policy-accepted'] && router.pathname !== '/polityka-prywatnosci' ? (
		<>
			<div id='overlay' />
			<div id='privacy-policy' className='text-center'>
				<h1 className='text-[14px] tracking-[1px] privacy-title uppercase font-[800]'>
					Polityka prywatności
				</h1>
				<p className='mt-5 text-sm'>
					Szanujemy Twoją prywatność. Wykorzystujemy pliki cookies, aby zapewnić najlepszą jakość
					korzystania z naszej witryny. Kontynuując przeglądanie, zgadzasz się na naszą &nbsp;
					<a href='/polityka-prywatnosci' className='font-bold cursor-pointer'>
						politykę prywatności
					</a>
				</p>

				<button className='px-5 py-1 mt-6 text-black font-[800]' onClick={handleAccept}>
					OK
				</button>
			</div>
		</>
	) : (
		<></>
	);
}
