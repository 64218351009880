import React, { useEffect } from 'react';
import Grupowe from '../../images/grupowe2.jpg';

type Props = {};

export default function Info({}: Props) {
	useEffect(() => {}, []);
	return (
		<div className='site--content forwhom--site'>
			<div className='w-[80%] ml-[15%] mr-auto forwhom--content'>
				<div className='flex justify-between gap-[150px] items-end forwhom--intro'>
					<h1 className='text-3xl text-opacity-50 text-black'>
						Liderki i liderzy, którzy wybierają współpracę z nami, mają wpływ na organizacje, chcą
						pracować nad własnym rozwojem oraz oferować możliwość rozwoju swoim zespołom. Zaliczają
						się do nich:
					</h1>
					<img src={Grupowe} className='w-[500px] rounded-[5px] object-cover' />
				</div>
				<div className='mt-[80px] forwhom--grid'>
					<div className='forwhom--item'>
						Właściciele <br /> oraz akcjonariusze firm prywatnych i korporacji
					</div>
					<div className='forwhom--item'>Prezesi oraz członkowie zarządów</div>
					<div className='forwhom--item'>
						Członkowie <br /> rad nadzorczych
					</div>
					<div className='forwhom--item'>
						Top-menedżerowie <br /> w firmach prywatnych <br />i korporacjach
					</div>
					<div className='forwhom--item'>
						Przedsiębiorcy rozwijający <br /> własne start-upy, <br />
						zarządzający nimi
					</div>
					<div className='forwhom--item'>
						Osoby planujące <br />i przechodzące proces sukcesji w firmach rodzinnych
					</div>
					<div className='forwhom--item'>
						Menedżerowie przeprowadzający <br />
						zmiany organizacyjne, własnościowe, procesy sprzedaży firm, zmian <br />w zarządach w
						ramach sukcesji lub procesu M&A
					</div>
					<div className='forwhom--item'>
						Inwestorzy start-upów <br />
						lub działających firm
					</div>
					<div className='forwhom--item'>
						Doświadczeni menedżerowie, przechodzący procesy zmiany zawodowej <br />
						oraz osobistej
					</div>
					<div className='forwhom--item'>
						Osoby i zespoły współpracujące bezpośrednio z liderami
					</div>
				</div>
			</div>
		</div>
	);
}
