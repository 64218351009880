import React, { useEffect } from 'react';
import Grupowe from '../../images/grupowe2.jpg';

type Props = {};

export default function Info({}: Props) {
	useEffect(() => {}, []);
	return (
		<div className='site--content w-[80%] mr-auto ml-[7%] pt-32'>
			<h1 className='text-2xl text-opacity-50 text-black'>
				Liderki i liderzy, którzy wybierają współpracę z nami, mają wpływ na organizacje, chcą
				pracować nad własnym rozwojem oraz oferować możliwość rozwoju swoim zespołom. Zaliczają się
				do nich:
			</h1>
			<div className='mt-[80px] forwhom--grid pb-20'>
				<div className='forwhom--item'>
					Właściciele oraz akcjonariusze firm prywatnych i korporacji
				</div>
				<div className='forwhom--item'>Prezesi oraz członkowie zarządów</div>
				<div className='forwhom--item'>Członkowie rad nadzorczych</div>
				<div className='forwhom--item'>Top-menedżerowie w firmach prywatnych i korporacjach</div>
				<div className='forwhom--item'>
					Przedsiębiorcy rozwijający własne start-upy, zarządzający nimi
				</div>
				<div className='forwhom--item'>
					Osoby planujące i przechodzące proces sukcesji w firmach rodzinnych
				</div>
				<div className='forwhom--item'>
					Menedżerowie przeprowadzający zmiany organizacyjne, własnościowe, procesy sprzedaży firm,
					zmian w zarządach w ramach sukcesji lub procesu M&A
				</div>
				<div className='forwhom--item'>Inwestorzy start-upów lub działających firm</div>
				<div className='forwhom--item'>
					Doświadczeni menedżerowie, przechodzący procesy zmiany zawodowej oraz osobistej
				</div>
				<div className='forwhom--item'>Osoby i zespoły współpracujące bezpośrednio z liderami</div>
			</div>
		</div>
	);
}
