import React from 'react';

type Props = {};

export default function Footer({}: Props) {
  return (
    <footer className='w-full text-black site--footer relative'>
      <div className='w-[15%] font-semibold ml-[32%] gap-1 pb-5 mt-10 text-end flex flex-col justify-between uppercase text-xs tracking-[3.5px]'>
        <label>
          Ludzie <span> NAJWAŻNIEJSI</span>
        </label>
        <label>
          Przyw&#243;dztwo <span> ODPOWIEDZIALNOŚĆ</span>
        </label>
        <label>
          Strategia <span> DOKĄD i JAK</span>
        </label>
        <label>
          Komunikacja <span> RELACJE</span>
        </label>
        <label>
          Finanse <span> NARZĘDZIA</span>
        </label>
      </div>
    </footer>
  );
}
